import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DropdownWithAvatars = ({ people, current, handleSelection }) => {
  const [selected, setSelected] = useState(current);

  const handleChange = (e) => {
    setSelected(e);
    if (handleSelection) handleSelection(e);
  }

  return (
    <Listbox value={selected} onChange={handleChange}>
      <div className="mt-1 relative">
        <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-2 pr-7 py-1 text-left cursor-default focus:outline-none sm:text-sm">
          <span className="flex items-center">
            {
              selected ?
                <>
                  {
                    selected.image &&
                      <img src={selected.image} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                  }
                  <span
                    className={classNames(
                      'ml-3 block truncate',
                      selected.name == 'Not assigned' ? 'text-gray-400': ''
                    )}
                    // className="ml-3 block truncate"
                  >
                    {selected.name}
                  </span>
                </> :
                <span className="ml-3 block truncate text-gray-400">Not assigned</span>

            }
          </span>
          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>

        <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
          <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {people.map((person) => (
              <Listbox.Option
                key={person.id}
                className={({ active }) =>
                  classNames(
                    active ? 'text-white bg-indigo-600' : 'text-gray-900',
                    'cursor-default select-none relative py-2 pl-3 pr-9'
                  )
                }
                value={person}
              >
                {({ selected, active }) => (
                  <>
                    <div className="flex items-center">
                      {
                        person.image &&
                          <img src={person.image} alt="" className="flex-shrink-0 h-6 w-6 rounded-full" />
                      }
                      <span className={classNames(
                          selected ? 'font-semibold' : 'font-normal',
                          person.image ? 'ml-3 block truncate' : 'block truncate')}>
                        {person.name}
                      </span>
                    </div>

                    {selected ? (
                      <span
                        className={classNames(
                          active ? 'text-white' : 'text-indigo-600',
                          'absolute inset-y-0 right-0 flex items-center pr-4'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default DropdownWithAvatars;