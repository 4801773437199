import TemplateList from './TemplateList';

const TemplateChoice = ({ currentStep, setTemplate }) => {

  if (currentStep !== 'template') return null;
  
  return (
    <>
      <TemplateList setTemplate={setTemplate} />
    </>
  )

}

export default TemplateChoice;