import axiosConfig from '../axiosConfig';

const setSiteStatus = (fhid, status) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/status`, { status: status })
    .then(() => resolve())
    .catch((err) => reject(err));
  })
}

export default setSiteStatus;