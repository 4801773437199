import axiosConfig from '../axiosConfig';
import { toast } from 'react-toastify';

import logActivity from './logActivity';

const getManageSSO = (fhId) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post('/api/manage', { fhId: fhId })
    .then((res) => resolve(res))
    .catch((err) => reject(err));
  })

}

const manageSite = (fhId) => {
  toast.promise(
    getManageSSO(fhId),
    {
      pending: 'Loading site manager',
      success: 'Opening site manager!',
      error: 'Unable to load site manager'
    }
  ).then((res) => {

    logActivity({
      message: `Accessed site admin`,
      fhId: fhId,
      mute: true
    })

    window.location.href = res.data.url;
  });

  // window.location.href = res.data

}

export default manageSite;