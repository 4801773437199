import { Link, useLocation } from "react-router-dom";

const tabs = [
  { name: 'Details', href: '', current: true },
  { name: 'DNS', href: '/dns', current: false },
  { name: 'Caddy', href: '/caddy', current: false },
  // { name: 'Actions', href: '/actions', current: false },
  // { name: 'Settings', href: '/settings', current: false },
  // { name: 'Activity Log', href: '/log', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SiteTabs = ({ path }) => {
  // const { path } = props.match.params;

  const location = useLocation();
  
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <Link
                to={`${path}${tab.href}`}
                key={tab.name}
                className={classNames(
                  location.pathname === `${path}${tab.href}`
                    ? 'border-tukios-dark text-tukios-dark'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-3 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
              </Link>
              // <a
              //   key={tab.name}
              //   href={tab.href}
              //   className={classNames(
              //     tab.current
              //       ? 'border-tukios-dark text-tukios-dark'
              //       : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              //     'whitespace-nowrap py-3 px-1 border-b-2 font-medium text-sm'
              //   )}
              //   aria-current={tab.current ? 'page' : undefined}
              // >
              //   {tab.name}
              // </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default SiteTabs;
