import NavItems from './NavItems';
import SidebarLink from './SidebarLink';
import SidebarDropdown from './SidebarDropdown';
// import getSidebarData from '../utils/getSidebarData';

const DesktopNavigation = () => {
  const userType = localStorage.getItem('userType');

  // useEffect(() => {
  //   getSidebarData()
  // }, []);

  return (
    <div className="mt-5 flex-1 flex flex-col">
      <nav className="flex-1 px-2 space-y-1">

        {
          NavItems.map((item) => (
            !item.subPages ?
              // <SidebarLink badge={ sidebarData?.[item?.path] } item={item} key={item.path ? item.path : item.href} />
              <SidebarLink item={item} key={item.path ? item.path : item.href} />
              :
              <SidebarDropdown item={item} key={item.name} />
          ))
        }
        
      </nav>
    </div>

)
}

export default DesktopNavigation;