import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ClipboardCopyIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';
import buildPreviewLink from "../../utils/buildPreviewLink";
import openSiteEditor from '../../utils/openSiteEditor';
import ContentShimmer from 'react-content-shimmer'
import logActivity from '../../utils/logActivity';
import StatusBadge from '../sites/StatusBadge';
import DeleteButton from '../sites/DeleteButton';
import Success from '../ui/modal/dialogs/Success';
import Stager from '../ui/modal/dialogs/Stager';
import { ModalContext } from "../../hooks/ModalContext";
import { useContext, useState, useEffect } from 'react';
import axiosConfig from '../../axiosConfig';
import { isAdmin, isDesigner } from '../../utils/users';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { stage } from '../../utils/promoteSite';
import socketIOClient from "socket.io-client";
import { fakeObitsRemoved } from '../../utils/validators';
import GoLive from '../ui/modal/dialogs/GoLive';

const SiteHeader = ({ site, updateSiteStatus, setShowConfetti }) => {
  let { handleModal } = useContext(ModalContext);

  // let [ siteStatus, setSiteStatus ] = useState(site.launcher_label);
  let [ statusLoading, setStatusLoading ] = useState(false);

  const goLive = (e) => {
    e.preventDefault();
    handleModal(<GoLive site={site} showConfetti={() => setShowConfetti(true)} updateSiteStatus={updateSiteStatus} />)
  }

  const stageSite = (e) => {
    e.preventDefault();

    // setStatusLoading(true);
    handleModal(<Stager site={site} showConfetti={() => setShowConfetti(true)} updateSiteStatus={updateSiteStatus} />)

    // setTimeout(() => {
    //   // stage(site, stageId)
    //   // .then((res) => {
    //   //   console.log(res);
    //   // })
    //   // .catch((err) => {
    //   //   console.log(err);
    //   // })
    // }, 500);

    // .then((result) => {
    //   setStatusLoading(false);
    //   updateSiteStatus('Staged');
    // })
    
  }

  useEffect(() => {
    console.log(site)
    // setSiteStatus(props.launcher_label);
  }, []);

  return (
    <div className="md:flex md:items-start md:justify-between md:space-x-5">
      <div className="flex items-start space-x-5">

        <div className="flex-shrink-0">
          <div className="relative">
            <span className="inline-flex items-center px-3 py-0.5 rounded text-sm font-medium bg-transparent text-tukios-dark border border-tukios-dark">
              {site.id}
            </span>
          </div>
        </div>
        {/*
          Use vertical padding to simulate center alignment when both lines of text are one line,
          but preserve the same layout if the text wraps without making the image jump around.
        */}
        <div className="-mt-1">
          <h1 className="text-2xl font-bold text-gray-900">
            {
              site.name ? site.name : 
              <ContentShimmer
                rounded='7px'
                style={{
                  width: '80%',
                  height: '28px'
                }}
              />
            }
          </h1>
          <StatusBadge siteStatus={site.launcher_label} />
          {/* <span className="inline-flex items-center mt-1 px-3 py-0.5 rounded-full text-sm font-medium bg-purple-100 text-purple-800">
            In Design
          </span> */}
          {/* <p className="text-sm font-medium text-gray-500">ID: 123</p> */}
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">

        {
          site.launcher_label === 'Test' &&
            // <ModalProvider>
              <DeleteButton />
            // </ModalProvider>
        }

        {/* <button
          onClick={() => manageSite(site.id)}
          className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
        >
          Manage
        </button> */}


        {
          site.duda_id &&
          <>
            <button
              type="button"
              onClick={() => openSiteEditor(site.duda_id, site.id)}
              className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
            >
              Edit
            </button>

            <span className="relative z-0 inline-flex items-stretch">
              <a
                href={buildPreviewLink(site.duda_id)}
                target="_blank"
                rel="noreferrer"
                className="relative inline-flex flex-grow items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-l-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
              >
                Preview
              </a>
              <CopyToClipboard
                text={buildPreviewLink(site.duda_id)}
                onCopy={() => toast.success('Preview link copied to clipboard!')}
              >
                <button
                  className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-r-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                >
                  <ClipboardCopyIcon className="-mx-1 h-4 w-4" />
                </button>
              </CopyToClipboard>
            </span>
          </>
        }

        {
          ((site.launcher_label === 'In Design' || site.launcher_label === 'In Review') && site.duda_id) ?
            <button
              type="button"
              onClick={stageSite}
              // onClick={() => fakeObitsRemoved(site.id)}
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
              disabled={statusLoading}
            >
              {statusLoading ? 'Loading...' : 'Stage Site'}
            </button>

            : (site.launcher_label === 'In Design' && (isDesigner() || isAdmin())) ?

            <Link
              to={{
                pathname: '/create',
                state: {
                  fhid: site.id
                }
              }}
              // type="button"
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
              // disabled={statusLoading}
            >
              Create Duda Project
            </Link>

            : site.launcher_label === 'Staged' ?

            <button
              type="button"
              onClick={goLive}
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
              disabled={statusLoading}
            >
              {statusLoading ? 'Loading...' : 'Go Live'}
            </button>
            
            : <></>
        }

      </div>
    </div>
  )
}

export default SiteHeader;