import { useState } from "react";
import { GlobeIcon } from "@heroicons/react/outline";

const DNS = () => {

  let [ dnsStatus, setDnsStatus ] = useState('NOT_LOADED');
  // let [ dnsLoaded, setDnsLoaded ] = useState(false);

  return (
    <>
    </>
  )
}

export default DNS;