import { Dialog } from '@headlessui/react'
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { useContext } from 'react';
import { ModalContext } from '../../../../hooks/ModalContext';
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'

const SuccessWithButtons = ({ title, messageText, posButtonText, negButtonText, actionFn, confetti=false }) => {

  let { handleModal } = useContext(ModalContext);

  const handleAction = () => {
    actionFn(() => {
      handleModal();
    });
  }
  const { width, height } = useWindowSize()

  return (
    <>
      {
        confetti &&
          <Confetti
            width={width}
            height={height}
            recycle={false}
          />
      }

      <div>
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100">
          <ExclamationCircleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-5">
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              {messageText}
            </p>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
          onClick={handleAction}
          >
          {posButtonText}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
          onClick={() => handleModal(false)}
        >
          {negButtonText}
        </button>
      </div>
    </>
  )
}

export default SuccessWithButtons;