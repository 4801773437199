import { useState } from "react";

const CaddyLookup = ({ findCaddyConfig, loadingLookup, handleChange, orgId, platform }) => {

  const inputClasses = ' appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none';


  return (
    <>
      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-2">
          <label htmlFor="orgId" className="block text-sm font-medium text-gray-700">
            Org ID
          </label>
          <div className="mt-1 flex rounded-md">
            <input
              type="text"
              name="orgId"
              placeholder={5}
              value={orgId}
              onChange={handleChange}
              className={inputClasses}
            />
          </div>
        </div>


        <div className="sm:col-span-4">
            <label htmlFor="platform" className="block text-sm font-medium text-gray-700">
              Obituary Platform
            </label>
            <select
              name="platform"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded"
              value={platform}
              onChange={handleChange}
            >
              <option value="legacy">Legacy (manage)</option>
              <option value="v1">New (manage2)</option>
            </select>
          </div>


      </div>

      <div className="flex justify-center mt-4">
        <button
          onClick={() => findCaddyConfig(platform, orgId)}
          className="px-4 py-2 text-sm font-medium rounded-md text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
        >
          {
            loadingLookup === false ? 'Find Configuration' : 'Loading...'
          }
        </button>
      </div>

    </>

  )  

}

export default CaddyLookup;