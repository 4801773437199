import axiosConfig from '../axiosConfig';
import logActivity from "./logActivity";
import Success from "../components/ui/modal/dialogs/Success";

const live = ({ id }) => {
  return Promise.resolve('yes');
}

const stage = ({ id, name }) => {

  return new Promise((resolve, reject) => {

    // setTimeout(() => {
    //   resolve();
    // }, 1500);

    axiosConfig.post(`/api/sites/${id}/stage`)
    .then(() => {
  
      logActivity({
        message: 'Site staged',
        logType: 'STAGE',
        fhId: id
      })

      resolve();
  
      // handleModal(<Success
      //   title='Site staged'
      //   message={`${name} has been staged successfully! Copy the preview link to share with the funeral home.`}
      //   buttonText='Dismiss'
      //   confetti={true}
      // />)
      
    })
    .catch((err) => reject(err));
  
  })

}

export { 
  stage,
  live
}