import { Dialog } from '@headlessui/react'
import { useContext, useState } from "react";
import { ModalContext } from "../../../../hooks/ModalContext";
import HashLoader from 'react-spinners/HashLoader';
import { BanIcon } from "@heroicons/react/solid";
import PromoteProgressStep from '../../../site/PromoteProgressStep';
import { live } from '../../../../utils/promoteSite';
import PromotionWarning from './PromotionWarning';
import {
  fakeObitsRemoved,
  realObitsAdded,
  validCollectionURLs,
  validFloralURLs } from '../../../../utils/validators';

const GoLive = ({ site, showConfetti, updateSiteStatus }) => {
  
  let { handleModal } = useContext(ModalContext);
  let [ stageStep, setStageStep ] = useState('VALIDATE');

  const checkSteps = (tempSteps) => {
    let waiting = tempSteps.filter(s => s.status === undefined);
    let errors = tempSteps.filter(s => s.status === 'ERROR');
    let warnings = tempSteps.filter(s => s.status === 'WARN');

    if (waiting.length > 0) return;

    setTimeout(() => {
      if (errors.length === 0) {

        setStageStep('GOING_LIVE');

        live(site)
        .then(() => {
          
          // updateSiteStatus('Live');

          // let warnings = tempSteps.filter(s => s.status === 'WARN');
          showConfetti();
          handleModal(false);

          // history.push(`/sites/id/${site.id}/dns`);

          if (warnings.length > 0) {
            handleModal(
              <PromotionWarning
                title='Hang on!'
                message='The go-live process is complete, but the following warnings were caught. Please double-check them ASAP.'
                buttonText='Will do'
                warnings={warnings}
              />
            );
          }

        })

      } else {
        setStageStep('ERROR');
        setSteps([...errors, ...warnings]);
      }
  
    }, 1000);
  }

  const completeStep = (id, status, description=null) => {
    let currentSteps = [...steps];
    let targetStep = steps.find(step => step.id == id);
    let targetStepIdx = steps.findIndex(step => step.id == id);
    targetStep.status = status;
    targetStep.description = description;
    currentSteps[targetStepIdx] = { ...targetStep };
    setSteps(currentSteps);
    checkSteps(currentSteps);
  }

  let [ steps, setSteps ] = useState([
    {
      id: 0,
      content: 'Fake obituaries removed',
      validator: () => {
        return new Promise((resolve, reject) => {
          fakeObitsRemoved(site.id)
          .then((result) => {
            if (result === true) resolve({ status: 'GOOD' })
            resolve({ status: 'WARN', description: `${result} found`})
          })
        });
      }
    },
    {
      id: 2,
      content: 'Real obituaries added',
      validator: () => {
        return new Promise((resolve, reject) => {
          realObitsAdded(site.id)
          .then((result) => {
            if (result === true) resolve({ status: 'GOOD' })
            let desc = `Only ${result} found (12 expected)`;
            if (result === 0) desc = 'None found';
            resolve({ status: 'WARN', description: desc })
          })  
        });
      }
    },
    {
      id: 3,
      content: 'Valid collection URLs',
      validator: () => {
        return new Promise((resolve, reject) => {
          validCollectionURLs(site.id)
          .then((res) => {
            let collections = res.data.collections;
            console.log(collections);
            if (collections.length === 0) {
              resolve({ status: 'GOOD' });
            } else {
              resolve({ status: 'ERROR', description: `Invalid collections: ${collections.map(c => c.name).join(', ')}` });
            }
          })
          .catch((err) => console.log(err))
        })
      }
    },
    {
      id: 4,
      content: 'Valid floral URLs',
      validator: () => {
        return new Promise((resolve, reject) => {
          validFloralURLs(site.id)
          .then((res) => {
            if (res.data.badLocations.length === 0) {
              
              resolve({ status: 'GOOD' });

            } else {

              let errDesc = ``;
              res.data.badLocations.forEach(loc => {
                errDesc += `${loc.error}: ${loc.name} (${loc.id})\ntest`
              })

              resolve({
                status: 'ERROR',
                description: errDesc
              })
            }
          })
        })
      }
    },
    // {
    //   id: 5,
    //   content: 'Obituary subscribers',
    //   validator: () => {
    //     return Promise.resolve()
    //   }
    // }
  ])

  return (
    <div>
      <div className="mx-auto relative flex items-center justify-center h-12 w-12">

        {
          stageStep !== 'ERROR' ?
            <HashLoader color='#76AB23' />
          : <BanIcon className="h-full w-full text-red-600" aria-hidden="true" />
        }
      </div>
      <div className="mt-3 text-center sm:mt-5">
        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
          {
            stageStep === 'VALIDATE' ?
              'Validating site...'
            : stageStep === 'GOING_LIVE' ?
              'Finishing up...'
            : stageStep === 'ERROR' ?
              'Please correct the following errors before going live.'
            : null
          }
        </Dialog.Title>

        <div className="mt-5 grid grid-cols-2 gap-4">
          {
            steps.map(step => (
              <PromoteProgressStep key={step.id} step={step} completeStep={completeStep} />
            ))
          }
        </div>

      </div>
    </div>

  )

}

export default GoLive;