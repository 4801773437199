import { AnnotationIcon } from "@heroicons/react/solid";

const Design = ({ currentStep, design, setDesign }) => {

  const inputClasses = 'shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none';

  const handleChange = (e) => {
    setDesign({ ...design, [e.target.name]: e.target.value });
  }

  if (currentStep.id !== 'design') return null;

  return (
    <form className="space-y-8 divide-y divide-gray-200">
      <div className="space-y-8 divide-y divide-gray-200">

        <div className='bg-white p-5 space-y-4 shadow rounded'>
          
          <div className='text-lg font-medium flex items-center'>
            <AnnotationIcon className='h-5 w-5 mb-0.5 mr-1 text-tukios-navy' />
            <div>Design Notes <span className="text-gray-500 text-sm font-normal">- Optional</span></div>
          </div>

          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

            <div className="sm:col-span-6">
              <div>
                <textarea
                  name="notes"
                  type="text"
                  placeholder="Does the client want a specific color scheme, or an obit layout different from the chosen template?"
                  value={design.notes}
                  onChange={handleChange}
                  className={inputClasses}
                ></textarea>
              </div>
            </div>

          </div>
        </div>

      </div>
    </form>
  )
}

export default Design;