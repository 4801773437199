import React, { Component } from 'react';
import LaunchButton from './LaunchButton';
import { ArrowCircleLeftIcon } from '@heroicons/react/solid';
import formatTemplateName from '../../utils/formatTemplateName';

export default class LaunchConfirmation extends Component {
  render() {

    if (this.props.currentStep !== 'confirmation') return null;

    console.log(this.props.details);

    return (
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

          <div className="sm:col-span-2">
            <div className="flex-1 flex flex-col p-3">
              <h3 className="mb-3 text-gray-900 text-base">
                <span className="font-medium">Template: </span>{formatTemplateName(this.props.template.template_name)}
              </h3>
              <img className="w-full flex-shrink-0 mx-auto" src={this.props.template.thumbnail_url} alt="" />
            </div>
          </div>

          <div className="sm:col-span-4">
            <div className="flex-1 flex flex-col p-3">
              <h3 className="mb-3 text-gray-900 text-xl">
                [{this.props.details.fhId}] {this.props.details.fhName}
                
                {
                  this.props.details.isTest && (
                    <div>
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                        <svg className="mr-1.5 h-2 w-2 text-yellow-400" fill="currentColor" viewBox="0 0 8 8">
                          <circle cx={4} cy={4} r={3} />
                        </svg>
                        Test Site
                      </span>
                    </div>
                  )
                }
              </h3>

              <div className="mt-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <dt className="text-sm font-medium text-gray-500">
                    Address
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {this.props.details.fhAddress}
                    {
                      this.props.details.fhAddress2 &&
                        <span>, {this.props.details.fhAddress2}</span>
                    }
                  </dd>
                </div>
  
                <div className="sm:col-span-3">
                  <dt className="text-sm font-medium text-gray-500">
                    Location
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {this.props.details.fhCity}, {this.props.details.fhState} {this.props.details.fhZip}
                  </dd>
                </div>
    
                <div className="sm:col-span-3">
                  <dt className="text-sm font-medium text-gray-500">
                    Email Address
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {this.props.details.fhEmail}
                  </dd>
                </div>
  
                <div className="sm:col-span-3">
                  <dt className="text-sm font-medium text-gray-500">
                    Phone Number
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {this.props.details.fhPhone}
                  </dd>
                </div>

                <div className="sm:col-span-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Domain Name
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    www.{this.props.details.fhDomain}
                  </dd>
                </div>
  
              </dl>
            </div>

            <div className="flex justify-end mt-10">
              <LaunchButton
                launchSite={this.props.launchSite.bind(this)}
              />
            </div>


            </div>
          </div>


        </div>

        <div className="pt-5 flex justify-start">
          <button
            onClick={this.props.backFn}
            className="transition-colors ml-3 inline-flex justify-center py-2 px-4 border border-transparent text-sm rounded-md text-gray-700 bg-transparent hover:bg-gray-200 focus:outline-none"
          >
            <ArrowCircleLeftIcon className="mr-2 w-5 h-5" aria-hidden="true" /> Back
          </button>
        </div>


      </div>
    )
  }
}