import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';

const linkClassNames = (isActive) => {
  return classNames(
    isActive ? 'bg-tukios-dark text-white' : 'text-white hover:bg-tukios-light',
      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
  );
}

const SidebarLink = ({ item }) => {
  const location = useLocation();
  
  // let isActive = location.pathname.startsWith(item.path);
  let isActive = location.pathname == item.path;
  if (item.path === '/') isActive = location.pathname === item.path;

  return (

    item.path ? 
      <NavLink
        to={item.path}
        key={item.name}
        className={linkClassNames(isActive)}
        >
        <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
        {item.name}
      </NavLink> :
      <a
        href={item.href}
        key={item.name}
        className={linkClassNames(isActive)}
        target='_blank'
        rel='noreferrer'
      >
        <item.icon className="mr-3 flex-shrink-0 h-6 w-6 text-white" aria-hidden="true" />
        {item.name}
      </a>
  )

}

export default SidebarLink;