import React, { useEffect } from 'react';
import classNames from 'classnames';

const SidebarPage = ({ docTitle, pageTitle, children, fullWidth }) => {

  useEffect(() => {
    if (docTitle) {
      document.title = docTitle;
    }
  // eslint-disable-next-line
  }, []);

  let widthClass = fullWidth === true ? 'w-full' : 'max-w-7xl';

  return (
    <main className="flex-1 relative overflow-y-auto focus:outline-none">
      <div className="py-6">
        {
          pageTitle &&
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">{pageTitle}</h1>
          </div>
        }


        <div className={
          classNames('mx-auto px-4 sm:px-6 md:px-8 max-w-7xl', {
            'max-w-7xl' : fullWidth !== true,
            'w-full': fullWidth === true
          })
        }>
          {children}
        </div>
      </div>
    </main>
  )
}

export default SidebarPage;
