import SidebarPage from "../SidebarPage";
import Table from "../ui/table/Table";
import TableBody from "../ui/table/TableBody";
import TableHeader from "../ui/table/TableHeader";
import TableHeading from "../ui/table/TableHeading";
import listSites from "../../utils/listSites";
import { useEffect, useState } from "react";
import LoaderRow from "../sites/LoaderRow";
import { Link } from "react-router-dom";
import axiosConfig from '../../axiosConfig';
import DropdownWithAvatars from "../ui/DropdownWithAvatars";
import { toast } from "react-toastify";
import logActivity from "../../utils/logActivity";
import { UserAddIcon } from "@heroicons/react/solid";
import { getDesignerFromUser } from "../../utils/users";
import assignDesigner from "../../utils/assignDesigner";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import ReactTooltip from 'react-tooltip';

const UnassignedSites = () => {

  let [ isLoading, setIsLoading ] = useState(false);
  let [ sites, setSites ] = useState(undefined);
  let [ designers, setDesigners ] = useState(undefined);

  const updateSites = () => {
    setIsLoading(true);
    listSites({
      designer: 'none',
      label: 'In Design',
      perPage: 100
    })
    .then((sitesData) => {
      setIsLoading(false);
      setSites(sitesData.data);
    })
    .catch((err) => alert(err));
  }

  const determineDesigner = (e) => {
    return new Promise((resolve, reject) => {
      if (e === 'me') {
        getDesignerFromUser()
        .then((designer) => {
          resolve(designer)
        })
      } else {
        resolve(e)
      }
    })
  }

  const setSiteDesigner = (fhid, e) => {

    // let selfAssign = e === 'me';
    // let designerId = undefined;
    // let designerName = undefined;

    // if (selfAssign === true) {
    //   let des = await getDesignerFromUser();
    //   designerId = des.designerId;
    // }
    // if (selfAssign !== true) designerId = e.id;

    determineDesigner(e)
    .then((designer) => {
      assignDesigner(fhid, designer.id)
      .then((res) => {
    
        logActivity({
          message: `${designer.name} assigned to site`,
          fhId: fhid,
          logType: 'DESIGNER'
        })
    
        // let newSites = sites.filter(site => site.id != fhid);
        // setSites(newSites);
        updateSites()
        toast.success(`${designer.name} assigned to site!`);
        if (res !== true) toast.warn(res);
  
      })
      .catch((err) => {
        toast.error('Error updating site designer');
        console.log(err);
      })  
    })


  }


  useEffect(() => {
    axiosConfig.get('/api/designers')
    .then((res) => {
      setDesigners([{ id: null, name: 'Not assigned', image: null }, ...res.data]);
    })

    updateSites()
  }, []);

  return (
    <SidebarPage docTitle='Unassigned Sites | Tukios Website Admin'>
      <div className='flex flex-col'>
        
        <Table bottomPad={true}>
          <TableHeader>
            <TableHeading name="ID" width="w-1/8" />
            <TableHeading name="Name" width="w-1/2" />
            <TableHeading name="Date Created" width="w-1/4" />
            <TableHeading name="" width="w-1/8" />
            <TableHeading name="Assign" width="w-1/3"/>
          </TableHeader>
          <TableBody>
            {
              sites !== undefined && isLoading === false ? (
                sites
                .filter(site => (site.launcher_label != 'Live' && site.launcher_label != 'Rebuild'))
                .map((site, siteIdx) => (
                  <tr
                    key={site.id}
                    className={siteIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center">
                      {site.id}
                      {
                        (site.urgent_build == 1) &&
                          <>
                            <ExclamationCircleIcon data-tip="Urgent build requested" className='h-5 w-5 text-red-500 ml-1' />
                            <ReactTooltip />
                          </>
                      }
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-900">
                      <Link to={{
                        pathname: `/sites/id/${site.id}`,
                        state: {
                          duda_id: site.duda_id,
                          name: site.name
                        }
                      }}>
                        {site.name}
                      </Link>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {site.pretty_date_created}
                    </td>

                    <td className='py-4 text-sm text-gray-900 text-right'>
                      <button
                        onClick={() => setSiteDesigner(site.id, 'me')}
                        type="button"
                        className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-tukios-green-dark hover:bg-tukios-green focus:outline-none"
                      >
                        <UserAddIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                    </td>

                    <td className="px-6 py-4 text-sm text-gray-900">
                      <div
                        className=''
                      >
                        {
                          designers !== undefined &&
                            <DropdownWithAvatars
                              people={designers}
                              handleSelection={(e) => setSiteDesigner(site.id, e)}
                            />
                        }
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                Array.from({ length: 3 }, (e, i) => (
                  <LoaderRow key={i} idx={i} />
                ))
              )
            }
          </TableBody>
        </Table>
      </div>
    </SidebarPage>
  )
}

export default UnassignedSites;
