import React, { Component } from 'react';
import SidebarPage from '../SidebarPage';
import SiteTable from '../sites/SiteTable';

export default class SitesPage extends Component {

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     sites: []
  //   }
  // }

  // componentDidMount() {
  //   axios.get('https://www.fhsitebuilder.com/duda/sites')
  //   .then((res) => {
  //     console.log(res.data);
  //     this.setState({
  //       sites: res.data
  //     })
  //   })
  // }

  render() {
    return (
      <SidebarPage docTitle='All Sites | Tukios Website Admin'>
        <SiteTable />
      </SidebarPage>
    )
  }
}