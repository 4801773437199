const StatsWrapper = (props) => {
  return (
    <div>
      {/* <h3 className="text-lg leading-6 font-medium text-gray-900">All-time Stats</h3> */}
      <dl className="mt-2 grid grid-cols-2 gap-5 lg:grid-cols-4">
        {props.children}
      </dl>
    </div>
  )
}

export default StatsWrapper;