import {
    ChartBarIcon,
    DesktopComputerIcon,
    LightningBoltIcon,
    // BookOpenIcon,
    LinkIcon,
    ClipboardCheckIcon,
    LockClosedIcon,
    ColorSwatchIcon,
    UserIcon
} from '@heroicons/react/outline'  

import { isAdmin } from '../utils/users'

// const adminSitesDropdown = [
//   { name: 'All Sites', path: '/sites' },
//   { name: 'Ready for Designer', path: '/sites/unassigned' },
//   { name: 'My Sites', path: '/sites/me' },    
// ]

const adminToolsDropdown = [
  { name: 'Link Site', path: '/link' },
  { name: 'Create Site', path: '/create/legacy' },
]
const adminFolder = { name: 'Admin', icon: LockClosedIcon, subPages: adminToolsDropdown };

const defaultNavItems = [
  { name: 'My Sites', icon: UserIcon, path: '/sites/me' },
  { name: 'All Sites', icon: DesktopComputerIcon, path: '/sites' },
  { name: 'New Website', icon: ClipboardCheckIcon, path: '/intake' },
  { name: 'Ready for Designer', icon: ColorSwatchIcon, path: '/sites/unassigned' },
]

// const userBasedNavItems = {
//   all: [
//     // { name: 'Dashboard', icon: ChartBarIcon, path: '/' },
//   ],
//   god: [
//     { name: 'All Sites', icon: DesktopComputerIcon, path: '/sites' },
//     { name: 'New Website', icon: ClipboardCheckIcon, path: '/intake' },
//     { name: 'My Sites', icon: UserIcon, path: '/sites/me' },
//     { name: 'Ready for Designer', icon: ColorSwatchIcon, path: '/sites/unassigned' },
//     // { name: 'Sites', icon: DesktopComputerIcon, subPages: adminSitesDropdown },
//     // { name: 'Create Site', icon: LightningBoltIcon, path: '/create' },
//     { name: 'Admin', icon: LockClosedIcon, subPages: adminToolsDropdown },
//     // { name: 'Link Site', icon: LinkIcon, path: '/link' }
//   ],
//   admin: [
//     { name: 'Sites', icon: DesktopComputerIcon, subPages: adminSitesDropdown },
//     // { name: 'Create Site', icon: LightningBoltIcon, path: '/create' },
//     { name: 'Intake Form', icon: ClipboardCheckIcon, path: '/intake' },
//     { name: 'Link Site', icon: LinkIcon, path: '/link' }
//   ],
//   ae: [
//     // { name: 'Dashboard', icon: ChartBarIcon, path: '/' },
//     { name: 'Intake Form', icon: ClipboardCheckIcon, path: '/intake' },
//   ],
//   designer: [
//     // { name: 'My Sites', icon: ClipboardCheckIcon, path: '/me' },
//     { name: 'My Sites', icon: DesktopComputerIcon, path: '/sites/me' },
//   ]
// }

const NavItemsGenerator = () => {

  if (isAdmin()) return [ ...defaultNavItems, adminFolder ];
  return defaultNavItems;

}

const NavItems = NavItemsGenerator();

// const NavItems = [
//     { name: 'Dashboard', icon: ChartBarIcon, path: '/' },
//     { name: 'Sites', icon: DesktopComputerIcon, path: '/sites' },
//     { name: 'Create Site', icon: LightningBoltIcon, path: '/create', exclude: ['ae', 'support'] },
//     
//     
//     // { name: 'Knowledge Base', href: 'https://tukios-website-docs.super.site', icon: BookOpenIcon }
// ]

export default NavItems;
