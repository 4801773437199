import axiosConfig from '../axiosConfig';
import { toast } from "react-toastify";

// const deleteSiteHelper = (fhid) => {
//   return new Promise((resolve, reject) => {
//     axiosConfig.delete(`/api/sites/${fhid}`)
//     .then(() => resolve())
//     .catch((err) => reject(err));
//   })
// }

const deleteSite = (fhid, cb) => {

  toast.promise(
    axiosConfig.delete(`/api/sites/${fhid}`),
    {
      pending: 'Deleting site...',
      success: 'Site deleted!',
      error: 'Unable to delete site'
    }
  ).then((res) => {
    cb();
  })
  .catch((err) => {
    cb(false);
  });

  
  // console.log('deleting...');
  // setTimeout(() => {
  //   console.log('deleted, hoe');
  //   cb(false);
  // }, 1000)

  // axiosConfig.post('/', {})
  // .then(() => {
  //   if (cb) return cb();
  // })
  // .catch((err) => {
  //   console.log(err);
  //   return false;
  // })
}

export default deleteSite;