import { ArrowLeftIcon, ArrowRightIcon, LightningBoltIcon } from "@heroicons/react/solid";
import { useState } from "react";

const IntakeNav = ({ allSteps, currentStep, setCurrentStep, completeStep, validate, submitForm }) => {

  let currentStepIdx = allSteps.findIndex(step => step.id == currentStep.id);

  let prevStep = null;
  let nextStep = null;

  // let [ showSave, setShowSave ] = useState(false);
  
  if ( currentStepIdx > 0 ) prevStep = allSteps[ currentStepIdx - 1 ];
  if ( currentStepIdx < allSteps.length - 1 ) nextStep = allSteps[ currentStepIdx + 1 ];

  // if ( currentStepIdx === allSteps.length - 1 ) setShowSave(true);

  const goToStep = (step) => {

    if (validate[currentStep.id]) {

      let validation = validate[currentStep.id]();

      if (validation.isValid === true) {

        completeStep(currentStep.id, true, validation.detailMessage);
        setCurrentStep(step);

      } else {

        let errorMessage = `Please fill out these fields: ${validation.errors.join(', ')}`;
        completeStep(currentStep.id, false);

        alert(errorMessage);

      }
      
    } else {
      
      completeStep(currentStep.id);
      setCurrentStep(step);
    }

  }

  return (

    <div className="mt-5 flex justify-between">

      {
        prevStep !== null ?
        <button
          type="button"
          onClick={() => goToStep(prevStep)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
        >
          <ArrowLeftIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
          { prevStep.name }
        </button> : <div></div>
      }

      {
        nextStep !== null ?
        <button
          type="button"
          onClick={() => goToStep(nextStep)}
          className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
        >
          { nextStep.name }
          <ArrowRightIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
        </button> :
        <button
          type="button"
          onClick={() => submitForm()}
          className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
        >
          Submit
          <LightningBoltIcon className="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
        </button>
      
      }
    </div>

  )

}

export default IntakeNav;