import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

const SiteFilter = ({ filters, selected, setSelected, placeholder }) => {

  // const [selected, setSelected] = useState(filters[0])

  // const handleChange = (e) => {
  //   setSelected(e);
  //   console.log(e);
  // }

  if (filters === undefined) return <></>

  if (placeholder) filters = [placeholder, ...filters];

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          {/* <Listbox.Label className="block text-right text-sm font-medium text-gray-700">Filter status</Listbox.Label> */}
          <div className="relative">
            <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none sm:text-sm">
              <div className="flex items-center">

                {
                  selected.color ? 
                    <span
                      className={classNames(
                        selected.color,
                        'flex-shrink-0 inline-block h-2 w-2 rounded-full'
                      )}
                    /> : selected.image ?
                    <>
                      <img src={selected.image} alt="" className="flex-shrink-0 h-4 w-4 rounded-full" />
                    </> : <span className='flex-shrink-0 inline-block h-2 w-2 rounded-full bg-gray-400'></span>
                }

                <span className="ml-3 block truncate">{selected.name}</span>
              </div>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {filters.map((filter) => (
                  <Listbox.Option
                    key={filter.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-tukios' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9'
                      )
                    }
                    value={filter}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">

                          {
                            filter.color ? 
                              <span
                                className={classNames(
                                  filter.color,
                                  'flex-shrink-0 inline-block h-2 w-2 rounded-full'
                                )}
                                aria-hidden="true"
                              /> : filter.image ?
                              <>
                                <img src={filter.image} alt="" className="flex-shrink-0 h-4 w-4 rounded-full" />
                              </> : <span className='flex-shrink-0 inline-block h-2 w-2 rounded-full bg-gray-400'></span>
                          }

                          {/* <span
                            className={classNames(
                              filter.color,
                              'flex-shrink-0 inline-block h-2 w-2 rounded-full'
                            )}
                            aria-hidden="true"
                          /> */}

                          <span
                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                          >
                            {filter.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-tukios',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
  
}

export default SiteFilter;