import axiosConfig from '../axiosConfig';

// const userType = localStorage.getItem('userType');

const isGod = () => {
  return userType() == 'god';
}

const isAdmin = () => {
  return userType() == 'admin' || userType() == 'god';
}

const isDesigner = () => {
  return userType() == 'designer';
}

const isAE = () => { 
  return userType() == 'ae';
}

const userType = () => {
  return localStorage.getItem('userType');
}

const userId = () => {
  return localStorage.getItem('userId');
}

const getDesignerFromUser = () => {
  return new Promise((resolve, reject) => {
    let userId = localStorage.getItem('userId');
    axiosConfig.get(`/api/designers/fromUser/${userId}`)
    .then((res) => {
      console.log(res.data)
      resolve(res.data)
    })
    .catch((err) => reject(err));
  })
}

const getUserFromDesigner = (designerId) => {
  return new Promise((resolve, reject) => {
    let userId = localStorage.getItem('userId');
    axiosConfig.get(`/api/designers/toUser/${designerId}`)
    .then((res) => {
      resolve(res.data)
    })
    .catch((err) => reject(err));
  })
}

export {
  isGod,
  isAdmin,
  isDesigner,
  isAE,
  userId,
  userType,
  getDesignerFromUser,
  getUserFromDesigner
}