import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router";
import SidebarPage from "../SidebarPage";
import ActivityFeed from "./ActivityFeed";
import SiteHeader from "./SiteHeader";
import axiosConfig from '../../axiosConfig';
import SiteTabs from "./SiteTabs";
import TabRouter from "./TabRouter";
import Confetti from 'react-confetti'
import useWindowSize from 'react-use/lib/useWindowSize'

const SitePage = (props) => {

  const location = useLocation();
  const { width, height } = useWindowSize()

  let [ site, setSite ] = useState({
    id: props.match.params.id,
    duda_id: location.state?.duda_id,
    name: location.state?.name
  })

  let [ siteLoaded, setSiteLoaded ] = useState(false);
  let [ showConfetti, setShowConfetti ] = useState(false);

  useEffect(() => {

    let params = new URLSearchParams(window.location.search);
    if (params.has('created')) {
      let created = params.get('created');
      window.history.replaceState({}, document.title, window.location.pathname);
      
      // eslint-disable-next-line
      if (created == 1) setShowConfetti(true);
    }

    axiosConfig.get(`/api/sites/${site.id}`)
    .then((res) => {
      setSite({...res.data});
      setSiteLoaded(true);
      document.title = `${site.id} ${res.data.name} | Tukios Website Admin`;
    })
  // eslint-disable-next-line
  }, [])

  const updateSiteStatus = (status) => {
    setSite({
      ...site,
      launcher_label: status
    })
  }

  return (
    <>
      {
        showConfetti &&
        <Confetti
          width={width}
          height={height}
          recycle={false}
        />
      }
      <SidebarPage docTitle={`${site.id} ${site.name} | Tukios Website Admin`}>
        <SiteHeader
          site={site}
          updateSiteStatus={updateSiteStatus}
          setShowConfetti={setShowConfetti}
          // duda_id={site.duda_id}
          // site_id={site.id}
          // firmName={site.name}
          // launcher_label={site.launcher_label}
        />
        <div className="mt-8 grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-4">
          
          <div className="space-y-4 lg:col-start-1 lg:col-span-1">
            <ActivityFeed path={props.match.url} site_id={site.id} />
          </div>

          <section className="space-y-4 lg:col-start-2 lg:col-span-3">
            <div className="bg-white px-4 pt-3 pb-5 shadow sm:rounded-lg sm:px-6">
              <SiteTabs path={props.match.url} />
              <div className="mt-5">
                <TabRouter path={props.match.path} site={site} siteLoaded={siteLoaded} />
              </div>
            </div>
            {/* <DetailCard site={site} /> */}
            {/* <Actions /> */}
          </section>


        </div>


      </SidebarPage>
    </>
  )

}

export default SitePage;
