import { useContext, useState } from "react";
import { ModalContext } from "../../hooks/ModalContext";
import { HomeIcon,
  UserCircleIcon,
  LocationMarkerIcon,
  PlusCircleIcon
} from '@heroicons/react/solid';
import AddLocationDialog from "../ui/modal/dialogs/AddLocation";
import ToggleWithLabel from '../ui/ToggleWithLabel';

const FH = ({ currentStep, fh, setFh, completeStep }) => {
  const inputClasses = 'shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none';

  let { handleModal } = useContext(ModalContext);

  const newLocation = (newLoc) => {
    let newFh = { ...fh };
    newFh.locations.push(newLoc);
    setFh(newFh);
  }

  const handleChange = (e) => {
    let val = e.target.value;

    console.log(e.target.name);

    let nameSplit = e.target.name.split('.');

    if (nameSplit.length > 1) {

      let section = nameSplit[0];
      let property = nameSplit[1];
  
      let newFh = {...fh};
      newFh[section][property] = val;
      setFh(newFh);

    } else {
      
      let property = e.target.name;

      let newFh = { ...fh };
      newFh[property] = val;
      setFh(newFh);

    }

  }

  const addLocation = () => {
    handleModal(
      <AddLocationDialog
        newLocation={newLocation}
      />
    )
  }

  const setUrgentBuild = (e) => {
    let newFh = {...fh};
    newFh.urgentBuild = e;
    setFh(newFh);
  }

  const setHostedObits = (e) => {

    let newFh = {...fh};
    newFh.hostedObits = e;
    setFh(newFh);

    // setDetails({ ...details, hostedObits: e });

  }

  if (currentStep.id !== 'fh') return null;

  return (
    <form className="space-y-8 divide-y divide-gray-200">
      <div className="space-y-8 divide-y divide-gray-200">

        <div className='bg-white p-5 space-y-4 shadow rounded'>
          
          <div className='text-lg font-medium flex items-center'>
            <HomeIcon className='h-5 w-5 mb-0.5 mr-1 text-tukios-navy' />
            <div>Funeral Home Details</div>
          </div>

          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

            <div className="sm:col-span-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <input
                  name="details.name"
                  type="text"
                  placeholder="Sonzini Mortuary"
                  onChange={handleChange}
                  value={fh.details.name}
                  className={inputClasses}
                  />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="provider" className="block text-sm font-medium text-gray-700">
                Provider
              </label>
              <select
                name="details.provider"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
                initialValues="chooseProvider"
                value={fh.details.provider}
                onChange={handleChange}
              >
                {/* <option disabled value="chooseProvider">Choose one</option> */}
                <option value="" disabled>Select one</option>
                <option>CFS</option>
                <option>FuneralOne</option>
                <option>Artic Designs</option>
                <option>FuneralTech</option>
                <option>Other</option>
              </select>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Website <span className="text-gray-400"> - Do not include `http` or `www`</span>
              </label>
              <div className="mt-1 flex shadow rounded-md">
                <span className="inline-flex shadow-none items-center px-3 rounded-l-md border border-r-0 bg-gray-50 text-gray-500 sm:text-sm">
                  www.
                </span>
                <input
                  name="details.url"
                  type="text"
                  placeholder="sonzinimortuary.com"
                  onChange={handleChange}
                  value={fh.details.url}
                  className={inputClasses + ' rounded-l-none shadow-none'}
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <ToggleWithLabel
                enabled={fh.hostedObits}
                setEnabled={setHostedObits}
                label='Hosted obituary site'
              />
            </div>

            <div className="sm:col-span-6">
              <ToggleWithLabel
                enabled={fh.urgentBuild}
                setEnabled={setUrgentBuild}
                label='Request urgent build'
              />
            </div>

            {
              fh.urgentBuild === true &&
              <div className="sm:col-span-6">
                <label htmlFor="urgentBuildDesc" className="block text-sm font-medium text-gray-700">
                Briefly explain the reason for an urgent build:
                </label>
                <div className="mt-1">
                  <input
                    name="urgentBuildDesc"
                    type="text"
                    onChange={handleChange}
                    value={fh.urgentBuildDesc}
                    className={inputClasses}
                    />
                </div>
              </div>

            }



          </div>
        </div>


        <div className='bg-white p-5 space-y-4 shadow rounded'>
          
          <div className='text-lg font-medium flex items-center'>
            <UserCircleIcon className='h-5 w-5 mb-0.5 mr-1 text-tukios-navy' />
            <div>Primary Contact Person</div>
          </div>


          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

            <div className="sm:col-span-6">
              <label htmlFor="contact.name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <input
                  name="contact.name"
                  type="text"
                  placeholder="John Doe"
                  onChange={handleChange}
                  value={fh.contact.name}
                  className={inputClasses}
                  />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="contact.phone" className="block text-sm font-medium text-gray-700">
                Phone
              </label>
              <div className="mt-1">
                <input
                  name='contact.phone'
                  type="text"
                  placeholder="(910) 260-2599"
                  onChange={handleChange}
                  value={fh.contact.phone}
                  className={inputClasses}
                  />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="contact.email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <div className="mt-1">
                <input
                  name="contact.email"
                  type="text"
                  placeholder="john@sonzinimortuary.com"
                  onChange={handleChange}
                  value={fh.contact.email}
                  className={inputClasses}
                  />
              </div>
            </div>

          </div>

        </div>


        <div className='bg-white p-5 space-y-4 shadow rounded'>
          
          <div className='text-lg font-medium flex items-center'>
            <LocationMarkerIcon className='h-5 w-5 mb-0.5 mr-1 text-tukios-navy' />
            <div>
              {
                fh.locations.length > 1 ? 'Locations' : 'Location'
              }
              {
                fh.locations.length === 0 &&
                  <span className="text-gray-500 text-sm font-normal"> - At least one is required</span>
              }
            </div>
          </div>

          <div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-3'>

            {
              fh.locations.map(loc => (

                <div
                  key={loc.id}
                  className='relative flex flex-col justify-center w-full border-2 border-gray-300 rounded-lg p-4'
                >
                  <h3 className='text-lg underline mb-2'>{loc.name}</h3>
                  <span className='mb-2'>
                    {loc.address}
                    <br/>
                    {loc.city}, {loc.state} {loc.zip}
                  </span>
                  { loc.email && <span>{loc.email}</span> }
                  { loc.phone && <span>{loc.phone}</span> }
                </div>

              ))
            }



            <button
              type="button"
              onClick={addLocation}
              className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none"
            >
              <PlusCircleIcon className='mx-auto h-12 w-12 text-gray-400' />
              <span className="mt-2 block text-sm font-medium text-gray-900">
                {
                  fh.locations.length > 0 ? 'Add another location' : 'Add a location'
                }
              </span>
            </button>
          </div>

          
        </div>

      </div>
    </form>
  )

}

export default FH;