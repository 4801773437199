import React, { useState, useContext } from 'react';
import InputMask from "react-input-mask";
import {
  ArrowCircleRightIcon,
  ArrowCircleLeftIcon
} from '@heroicons/react/solid';
import ToggleWithLabel from '../ui/ToggleWithLabel';
import { useForm } from "react-hook-form";
import classNames from 'classnames';
import axiosConfig from '../../axiosConfig';
import FuneralHomeExists from './FuneralHomeExists';
import { ModalContext } from '../../hooks/ModalContext';
import DangerDialog from '../ui/modal/dialogs/Danger';
import FHIDStatusBadge from './FHIDStatusBadge';

const FuneralHomeDetails = (props) => {

  const inputClasses = 'shadow appearance-none border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline';

  const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm();

  let [ isTest, setIsTest ] = useState(false);

  let { handleModal } = useContext(ModalContext);

  let [ fhidStatus, setFhidStatus ] = useState('NOT_SET');

  let [ tempFirm, setTempFirm ] = useState({});

  const setTestSite = (e) => {
    setIsTest(e);
  }

  const onSubmit = (data) => {
    console.log(data);

    if (fhidStatus === 'NOT_IN_USE') {
      props.saveDetails({ ...data, isTest: isTest, useExisting: false });
    } else if (fhidStatus === 'FH_EXISTS') {
      handleModal(<FuneralHomeExists firm={tempFirm} existingFirmSubmit={existingFirmSubmit} />);
    } else if (fhidStatus === 'DUDA_EXISTS') {
      handleModal(<DangerDialog
        title='Hang on!'
        messageText={`It looks like ${tempFirm.name} (ID: ${tempFirm.id}) already has a Duda site.`}
        actionText='Cancel and view site'
        cancelText='Enter a different ID'
        actionFn={() => {window.location.href = `/sites/${tempFirm.id}`}}
      />)
    }

  }

  const existingFirmSubmit = (foundFirm, cb) => {
    
    setValue('fhId', foundFirm.id);
    setValue('fhName', foundFirm.name);
    setValue('fhAddress', foundFirm.address);
    setValue('fhCity', foundFirm.city);
    setValue('fhState', foundFirm.state);
    setValue('fhZip', foundFirm.zip);
    setValue('fhEmail', foundFirm.email);
    setValue('fhPhone', foundFirm.phone);
    setValue('fhDomain', foundFirm.apex);

    let vals = getValues();

    props.saveDetails({ ...vals, isTest: isTest, useExisting: true });

    cb();
  }

//   <DangerDialog
//   title='Delete Site'
//   actionText='Delete'
//   messageText='Are you sure you want to delete this site? This action cannot be undone.'
//   actionFn={faker}
// />

  const fhIdSearch = (e) => {
    console.log(e.target.value);
    axiosConfig.get(`/api/sites/search?fhid=${e.target.value}`)
    .then((res) => {
      console.log(res.data);

      let fhInfo = res.data.firm;
      setTempFirm(fhInfo);

      if (fhInfo) {
        if (!fhInfo.duda_id) {
          setFhidStatus('FH_EXISTS');
          handleModal(<FuneralHomeExists firm={fhInfo} existingFirmSubmit={existingFirmSubmit} />);
        } else {
          setFhidStatus('DUDA_EXISTS');
          handleModal(<DangerDialog
            title='Hang on!'
            messageText={`It looks like ${fhInfo.name} (ID: ${fhInfo.id}) already has a Duda site.`}
            actionText='Cancel and view site'
            cancelText='Enter a different ID'
            actionFn={() => {window.location.href = `/sites/${fhInfo.id}`}}
          />)
        }
      } else {
        setFhidStatus('NOT_IN_USE');
      }
    })
  }

  if (props.currentStep !== 'details') return null;

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

            <div className="sm:col-span-6 flex justify-end items-right">
              <ToggleWithLabel
                label='Test Site'
                enabled={isTest}
                setEnabled={setTestSite}
              />
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Funeral Home ID
              </label>


              <div className="mt-1 flex shadow rounded-md">
                <input
                  id="fhId"
                  {...register('fhId', { required: true, valueAsNumber: true })}
                  type="number"
                  placeholder="123"
                  onBlur={fhIdSearch}
                  className={classNames(inputClasses, 'rounded-r-none shadow-none', {
                    'border-red-500': errors.fhId
                  })}
                />
                <span className="inline-flex shadow-none items-center px-3 rounded-r-md border border-l-0 bg-gray-50 text-gray-500 sm:text-sm">

                  <FHIDStatusBadge fhidStatus={fhidStatus} />

                </span>
              </div>



              {/* <div className="mt-1">
                <input
                  id="fhId"
                  {...register('fhId', { required: true })}
                  type="number"
                  placeholder="123"
                  onBlur={fhIdSearch}
                  className={classNames(inputClasses, {
                    'border-red-500': errors.fhId
                  })}
                />
              </div> */}
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <input
                  id="fhName"
                  {...register('fhName', { required: true })}
                  type="text"
                  placeholder="Sonzini Mortuary"
                  // onChange={this.handleChange}
                  // value={details.fhName}
                  className={classNames(inputClasses, {
                    'border-red-500': errors.fhName
                  })}
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Address
              </label>
              <div className="mt-1">
                <input
                  id="fhAddress"
                  {...register('fhAddress', { required: true })}
                  type="text"
                  placeholder="208 West Main Street"
                  // onChange={this.handleChange}
                  // value={details.fhAddress}
                  className={classNames(inputClasses, {
                    'border-red-500': errors.fhAddress
                  })}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                Address Line 2
              </label>
              <div className="mt-1">
                <input
                  id="fhAddress2"
                  {...register('fhAddress2')}
                  type="text"
                  placeholder="Suite 200"
                  // onChange={this.handleChange}
                  // value={details.fhAddress2}
                  className={classNames(inputClasses)}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                City
              </label>
              <div className="mt-1">
                <input
                  id="fhCity"
                  {...register('fhCity', { required: true })}
                  type="text"
                  placeholder="Clinton"
                  // onChange={this.handleChange}
                  // value={details.fhCity}
                  className={classNames(inputClasses, {
                    'border-red-500': errors.fhCity
                  })}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                State / Province
              </label>
              <div className="mt-1">
                <input
                  id="fhState"
                  {...register('fhState', { required: true, maxLength: 2 })}
                  type="text"
                  placeholder="NC"
                  // onChange={this.handleChange}
                  // value={details.fhState}
                  className={classNames(inputClasses, {
                    'border-red-500': errors.fhState
                  })}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="zip" className="block text-sm font-medium text-gray-700">
                ZIP / Postal
              </label>
              <div className="mt-1">
                <input
                  id="fhZip"
                  {...register('fhZip', { required: true })}
                  type="number"
                  placeholder="28328"
                  // onChange={this.handleChange}
                  // value={details.fhZip}
                  className={classNames(inputClasses, {
                    'border-red-500': errors.fhZip
                  })}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="fhEmail"
                  {...register('fhEmail', { required: true })}
                  type="text"
                  placeholder="john@sonzinimortuary.com"
                  // onChange={this.handleChange}
                  // value={details.fhEmail}
                  className={classNames(inputClasses, {
                    'border-red-500': errors.fhEmail
                  })}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <div className="mt-1">
                <InputMask
                  id="fhPhone"
                  {...register('fhPhone', { required: true })}
                  type="text"
                  placeholder="(910) 590-2534"
                  mask="(999) 999-9999"
                  // onChange={this.handleChange}
                  // value={details.fhPhone}
                  className={classNames(inputClasses, {
                    'border-red-500': errors.fhPhone
                  })}
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Domain name
              </label>
              <div className="mt-1 flex shadow rounded-md">
                <span className="inline-flex shadow-none items-center px-3 rounded-l-md border border-r-0 bg-gray-50 text-gray-500 sm:text-sm">
                  www.
                </span>
                <input
                  id="fhDomain"
                  {...register('fhDomain', { required: true, pattern: /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/ })}
                  type="text"
                  placeholder="sonzinimortuary.com"
                  // onChange={this.handleChange}
                  // value={details.fhDomain}
                  className={classNames(inputClasses, 'rounded-l-none shadow-none', {
                    'border-red-500': errors.fhDomain
                  })}
                />
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="pt-5">
        <div className="flex justify-between">
          <button
            onClick={props.backFn}
            className="transition-colors ml-3 inline-flex justify-center py-2 px-4 border border-transparent text-sm rounded-md text-gray-700 bg-transparent hover:bg-gray-200 focus:outline-none"
          >
            <ArrowCircleLeftIcon className="mr-2 w-5 h-5" aria-hidden="true" /> Back
          </button>
          <button
            type='submit'
            className="transition-colors ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
          >
            Next <ArrowCircleRightIcon className="ml-2 w-5 h-5 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </form>
  )
}

export default FuneralHomeDetails;

// export default class FuneralHomeDetails extends Component {

//   constructor(props) {
//     super(props);

//     this.handleChange = this.handleChange.bind(this);
//     this.onSubmit = this.onSubmit.bind(this);

//     this.state = {
//       fhId: '',
//       fhName: '',
//       fhAddress: '',
//       fhAddress2: '',
//       fhCity: '',
//       fhState: '',
//       fhZip: '',
//       fhEmail: '',
//       fhPhone: '',
//       fhDomain: '',
//       isTest: false
//     }
//   }

//   setTestSite(e) {
//     this.setState({
//       ...this.state,
//       isTest: e
//     })
//   }

//   handleChange(e) {
//     this.setState({
//       ...this.state,
//       [e.target.id]: e.target.value
//     })
//   }

//   onSubmit(e) {
//     e.preventDefault();
//     this.props.saveDetails(this.state);
//   }

//   render() {

//     if (this.props.currentStep !== 'details') return null;

//     return (
//     )
//   }
// }