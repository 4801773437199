import { useEffect, useState } from "react";
import LoaderRow from "./LoaderRow";
import SiteRow from "./SiteRow";
import axiosConfig from '../../axiosConfig';
import SiteFilter from "./SiteFilter";
import { SearchIcon } from "@heroicons/react/outline";
import { toast } from 'react-toastify';
import SitePagination from "./SitePagination";
import Table from "../ui/table/Table";
import TableHeader from "../ui/table/TableHeader";
import TableHeading from "../ui/table/TableHeading";
import TableBody from "../ui/table/TableBody";
import listSites from "../../utils/listSites";
import logActivity from "../../utils/logActivity";
import assignDesigner from "../../utils/assignDesigner";

const statusFilters = [
  { id: '*', name: 'Any status', color: 'bg-gray-400' },
  { id: 'Live', name: 'Live', color: 'bg-green-400' },
  { id: 'Staged', name: 'Staged', color: 'bg-yellow-400' },
  { id: 'In Design', name: 'In Design', color: 'bg-indigo-400' },
  { id: 'In Review', name: 'In Review', color: 'bg-sky-400' },
  { id: 'Rebuild', name: 'Rebuild', color: 'bg-teal-400' },
  { id: 'Test', name: 'Test', color: 'bg-red-400' },
]

const allDesigners = {
  id: '*',
  name: 'Any designer',
  color: 'bg-gray-400'
}

const SiteTable = (props) => {

  let [ sites, setSites ] = useState(undefined);
  let [ page, setPage ] = useState(1);
  let [ perPage, setPerPage ] = useState(20);
  let [ totalSites, setTotalSites ] = useState(0);

  let [ isLoading, setIsLoading ] = useState(false);

  let [ isSearching, setIsSearching ] = useState(false);
  let [ typingTimeout, setTypingTimeout ] = useState(0);

  let [ designers, setDesigners ] = useState(undefined);
  let [ filter, setFilter ] = useState(statusFilters[0]);
  let [ designerFilter, setDesignerFilter ] = useState(allDesigners)
  let [ searchTerm, setSearchTerm ] = useState('');

  const handleStatusChange = (e) => {
    setFilter(e);
    updateSites({ label: e.id, tempPage: 1 })
  }

  const handleDesignerChange = (e) => {
    setDesignerFilter(e);
    updateSites({ designer: e.id, tempPage: 1 })
  }

  const updateSiteLabelDisplay = (fhid, label) => {
    
    let siteToUpdate = sites.find(site => site.id == fhid);
    siteToUpdate.launcher_label = label;

    let currentSites = sites.filter(site => site.id != fhid);

    setSites([
      ...currentSites,
      siteToUpdate
    ]);

  }

  const setSiteDesigner = (fhid, e) => {
    console.log(e);

    let designerId = e.id == 'none' ? null : e.id;

    assignDesigner(fhid, designerId)
    .then((res) => {
      
      logActivity({
        message: `${e.name} assigned to site`,
        fhId: fhid,
        logType: 'DESIGNER'
      })

      let currentSites = [...sites];
      let currentSiteIndex = currentSites.map(s => s.id).indexOf(fhid);
      let currentSite = {...currentSites[currentSiteIndex]};
      
      currentSite.designer_id = e.id;
      currentSite.designer_name = e.name;
      currentSite.designer_image = e.image;

      currentSites[currentSiteIndex] = currentSite;

      setSites(currentSites);

      toast.success(`${e.name} assigned to site!`);
      if (res !== true) toast.warn(res);

      // let currentSite = sites.filter(site => site.id == fhid)[0];

      // setSites([
      //   ...sites,
      //   {
      //     designer_id: e.id,
      //     ...currentSite
      //   }
      // ])

      console.log('designer updated');
    })
    .catch((err) => {
      toast.error('Error updating site designer');
    })

  }

  const goToPage = (pageNumber) => {
    setPage(pageNumber);
    updateSites({ tempPage: pageNumber });
  }

  const clearFilters = () => {
    setSearchTerm('');
    setDesignerFilter(allDesigners);
    setFilter(statusFilters[0]);
    setPage(1);

    updateSites({ query: null, designer: null, label: null, tempPage: 1 })
  }

  const handleSearch = (e) => {

    if (typingTimeout) clearTimeout(typingTimeout);

    setSearchTerm(e.target.value);
    setIsSearching(false);
    setTypingTimeout(setTimeout(() => {
      updateSites({ query: e.target.value, tempPage: 1 });
      setPage(1);
    }, 100));
  }

  const updateSites = ({ query, tempPage, designer, label }={}) => {
    // query, page, perPage, designer, label
    setIsLoading(true);
    
    listSites({
      query: query !== undefined ? query : searchTerm,
      page: tempPage !== undefined ? tempPage : page,
      perPage: perPage,
      designer: designer !== undefined ? designer : designerFilter.id,
      label: label !== undefined ? label : filter.id
    })
    .then((sitesData) => {
      setSites(sitesData.data);
      setPage(sitesData.page);
      setPerPage(sitesData.perPage);
      setTotalSites(sitesData.totalSites);
      setIsLoading(false);
    })
    .catch((err) => {
      console.log(err);
      toast.error('Unable to load sites');
    })
  }

  // const listSites = ({ query, tempPage, designer, label }={}) => {

  //   setIsLoading(true);

  //   axiosConfig.get('/api/sites', {
  //     params: {
  //       page: tempPage !== undefined ? tempPage : page,
  //       perPage: perPage,
  //       query: query !== undefined ? query : searchTerm,
  //       designer: designer !== undefined ? designer : designerFilter.id,
  //       label: label !== undefined ? label : filter.id
  //     }
  //   })
  //   .then((res) => {
  //     setSites(res.data.data);
  //     setPage(res.data.page);
  //     setPerPage(res.data.perPage);
  //     setTotalSites(res.data.totalSites);
  //     setIsLoading(false);
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     toast.error('Unable to load sites');
  //   })
    
  // }

  useEffect(() => {

    updateSites();

    axiosConfig.get('/api/designers')
    .then((res) => {
      // let d = {};
      // res.data.forEach(designer => {
      //   d[designer.id] = {
      //     name: designer.name,
      //     image: designer.image
      //   }
      // })
      // console.log(d);
      // setDesigners(d);
      setDesigners([{ id: 'none', name: 'Not assigned', image: null }, ...res.data]);
    })
  }, [])

  return (
    <div className="flex flex-col">
      <div className='flex flex-col lg:flex-row justify-between items-start mb-1'>
        <div className='w-full lg:w-1/3'>
          
          <div className="flex rounded-lg mb-2 lg:mb-0 lg:mr-2 border">
            <span className="inline-flex shadow-none items-center px-3 rounded-l-lg border border-r-0 bg-gray-50 text-gray-500 sm:text-sm">
              <SearchIcon className='w-4 h-4' />
            </span>
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
              className='appearance-none border-gray-200 rounded-r-lg w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
            />
          </div>
          <button
            onClick={clearFilters}
            className='text-sm text-gray-400'
          >
            Clear filters
          </button>

        </div>
        <div className='w-full lg:mr-2 lg:w-1/3'>
          <SiteFilter
            placeholder={allDesigners}
            filters={designers}
            selected={designerFilter}
            setSelected={handleDesignerChange} />
        </div>
        <div className='w-full lg:w-1/3'>
          <SiteFilter
            filters={statusFilters}
            selected={filter}
            setSelected={handleStatusChange} />
        </div>
      </div>
      {/* <div className="none--overflow-x-auto sm:-mx-6 lg:-mx-8"> */}
      <div className="none--overflow-x-auto">
        <Table>
          <TableHeader>
            <TableHeading name="ID" />
            <TableHeading name="Name" width="w-1/4" />
            <TableHeading name="Designer" width="w-1/5" />
            <TableHeading name="Status" align='center' width="w-1/6" />
            <TableHeading name="Date Created" align="center" width="w-1/6" />
            <TableHeading name="Actions" align='right' width="w-1/5" />
          </TableHeader>
          <TableBody>
            {
              sites !== undefined && designers !== undefined && isLoading === false ? (
                sites
                // .filter(site => filter.id === '*' ? true : site.launcher_label === filter.id)
                // .filter(site => designerFilter.id === '*' ? true : site.designer_id === designerFilter.id)
                // .filter(site => searchTerm === '' ? true : (site.name.toLowerCase().includes(searchTerm.toLowerCase()) || site.id === searchTerm))
                .map((site, siteIdx) => (
                  <SiteRow
                    key={site.id}
                    site={site}
                    siteIdx={siteIdx}
                    designer={site.designer_id ? {
                      name: site.designer_name,
                      image: site.designer_image
                    } : null}
                    designers={designers}
                    setSiteDesigner={setSiteDesigner}
                    updateSiteLabelDisplay={updateSiteLabelDisplay} />
                ))
              ) : (
                Array.from({ length: 3 }, (e, i) => (
                  <LoaderRow idx={i} />
                ))
              )
            }
          </TableBody>
        </Table>
      </div>
      {
        (sites !== undefined && isLoading === false) &&
        <SitePagination
          page={page}
          perPage={perPage}
          totalSites={totalSites}
          goToPage={goToPage}
        />
      }
    </div>
  )

}

export default SiteTable;