import { Component } from 'react';
import { CheckIcon } from '@heroicons/react/solid'
import { FaSpinner } from 'react-icons/fa';

export default class LaunchButton extends Component {

  constructor(props) {
    super(props);

    this.launchSiteHelper = this.launchSiteHelper.bind(this);

    this.state = {
      loading: false
    }
  }

  launchSiteHelper(e) {
    this.setState({
      loading: true
    })
    this.props.launchSite(e);
  }

  render() {

    let buttonClasses = 'w-full transition-colors inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-tukios hover:bg-tukios-dark focus:outline-none';

    if (this.state.loading) {
      return (
        <button 
          className={buttonClasses}>
          <FaSpinner className="mr-2 w-5 h-5 text-white icon-spin" /> Loading...
        </button>
      )
    }

    return (
      <button
        onClick={this.launchSiteHelper}
        className={buttonClasses}
      >
        <CheckIcon className="mr-2 w-5 h-5 text-white" aria-hidden="true" /> Launch Site
      </button>
    );
  }
}

