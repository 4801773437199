import { Link } from "react-router-dom";
import DropdownWithAvatars from "../ui/DropdownWithAvatars";
import SiteActions from "./SiteActions"
import StatusBadge from './StatusBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrello } from '@fortawesome/free-brands-svg-icons'
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import ReactTooltip from 'react-tooltip';

const SiteRow = ({ site, siteIdx, designer, designers, setSiteDesigner, updateSiteLabelDisplay }) => {
    return (
    <tr key={site.id} className={siteIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center">
        {
          site.trello_card_id &&
          <a className="mr-2 text-md" href={`https://trello.com/c/${site.trello_card_id}`}>
            <FontAwesomeIcon icon={faTrello} />
          </a>
        }
        {site.id}
        {
          (site.urgent_build == 1) &&
            <>
              <ExclamationCircleIcon data-tip="Urgent build requested" className='h-5 w-5 text-red-500 ml-1' />
              <ReactTooltip />
            </>
        }
      </td>
      <td className="px-6 py-4 text-sm text-gray-900">
        <Link to={{
          pathname: `/sites/id/${site.id}`,
          state: {
            duda_id: site.duda_id,
            name: site.name
          }
        }}>
          {site.name}
        </Link>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <DropdownWithAvatars
          people={designers}
          current={designer}
          handleSelection={(e) => setSiteDesigner(site.id, e)}
        />
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
        <StatusBadge
          siteStatus={site.launcher_label} />
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
        {site.pretty_date_created}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium flex justify-end items-center">
        <SiteActions
          launcher_label={site.launcher_label}
          duda_id={site.duda_id}
          site_id={site.id}
          is_test={site.launcher_label == 'Test'}
          updateSiteLabelDisplay={updateSiteLabelDisplay}
          trello_card_id={site.trello_card_id}
        />

      </td>
    </tr>
  )
}

export default SiteRow;