import { useContext } from 'react';
import ReactDOM from 'react-dom';
import { ModalContext } from '../../../hooks/ModalContext';
import ModalDialog from './ModalDialog';

const Modal = () => {

  let { modalContent, handleModal, isOpen } = useContext(ModalContext);

  return ReactDOM.createPortal(
    <ModalDialog open={isOpen} setOpen={handleModal}>
      {modalContent}
    </ModalDialog>,
  // <ModalBackdrop>
  //   <ModalDialog>
  //     {modalContent}
  //   </ModalDialog>
  // </ModalBackdrop>,
  document.querySelector('#modal-root')
)  


}

export default Modal;