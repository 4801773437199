import React from 'react';
import SidebarPage from '../SidebarPage';
import { Link } from 'react-router-dom';
import {
  DesktopComputerIcon,
  LightningBoltIcon,
  LinkIcon,
  BookOpenIcon
} from '@heroicons/react/outline'


// import Stat from '../dashboard/Stat';
// import StatsWrapper from '../dashboard/StatsWrapper';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const actions = [
  {
    title: 'View all sites',
    description: 'Search and manage all sites on our platform',
    path: '/sites',
    icon: DesktopComputerIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
  },
  {
    title: 'Create a new site',
    description: 'Create a new funeral home and website project',
    path: '/create',
    icon: LightningBoltIcon,
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
  },
  {
    title: 'Link an existing site',
    description: 'Connect an existing funeral home to a website project',
    path: '/link',
    icon: LinkIcon,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',

  },
  {
    title: 'Learn about the platform',
    description: 'Find tutorials, best practices, workflows, and more',
    href: 'https://tukios-website-docs.super.site',
    icon: BookOpenIcon,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50'
  },
]

// const stats = [
//   { id: 1, name: 'Sites Launched', stat: '142', icon: LightningBoltIcon, change: '5.4%', changeType: 'increase' },
//   { id: 2, name: 'Sites Staged', stat: '212', icon: PaperAirplaneIcon, change: '7.2%', changeType: 'increase' },
//   { id: 3, name: 'Avg. Days to Live', stat: '11.3', icon: CalendarIcon, change: '3.2%', changeType: 'decrease' },
// ]

const DashboardPage = () => {

  return (
    <SidebarPage
      docTitle='Dashboard | Tukios Website Admin'
    >

      <div className="rounded-lg overflow-hidden divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
        {actions.map((action, actionIdx) => (
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
              actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
              actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
              actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
              'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
            )}
          >
            <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  'rounded-lg inline-flex p-3 ring-4 ring-white'
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                {
                  action.path ?
                    <Link
                      to={{ pathname: action.path }}
                      className="focus:outline-none"
                    >
                      <span className="absolute inset-0" aria-hidden="true" />
                      {action.title}
                    </Link> :
                    <a
                      href={action.href}
                      className="focus:outline-none"
                    >
                      <span className="absolute inset-0" aria-hidden="true" />
                      {action.title}
                    </a>
                }
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                {action.description}
              </p>
            </div>
            <span
              className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        ))}
      </div>


      {/* <StatsWrapper>
        {
          stats.map(stat => (
            <Stat key={stat.id} stat={stat} />
          ))
        }
      </StatsWrapper> */}
    </SidebarPage>
  )

}

export default DashboardPage;
