import React from 'react';
import DesktopNavigation from './DesktopNavigation';
import logo from './assets/tukios-logo-alt.png';

const DesktopSidebar = () => {

  return (
    <div className="hidden bg-tukios md:flex md:flex-shrink-0">
      <div className="flex flex-col w-64">

        <div className="flex flex-col flex-grow pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center flex-shrink-0 px-4">
            <img
              className="h-8 w-auto"
              src={logo}
              alt="Tukios"
            />
          </div>

          {/* DesktopNavigation */}
          <DesktopNavigation
            // sidebarData={sidebarData}
          />
          
        </div>
      </div>
    </div>

  )
}

export default DesktopSidebar;
