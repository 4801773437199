import { useState } from "react";
import CaddyLookup from "./CaddyLookup"
import CaddyBuilder from "./CaddyBuilder";
import axiosConfig from './../../../axiosConfig';
import { toast } from "react-toastify";

const Caddy = ({ site }) => {

  let [ lookup, setLookup ] = useState(false);
  let [ loadingLookup, setLoadingLookup ] = useState(false);
  let [ storedConfig, setStoredConfig ] = useState(undefined);

  let [ orgId, setOrgId ] = useState(site.id);
  let [ platform, setPlatform ] = useState('legacy');

  const handleChange = (e) => {
    if (e.target.name == 'platform') setPlatform(e.target.value);
    if (e.target.name == 'orgId') setOrgId(e.target.value);
  }

  const findCaddyConfig = (platform, orgId) => {
    setLoadingLookup(true);
    axiosConfig.get(`/api/caddy/${platform}/${orgId}`)
    .then((res) => {
      console.log(res.data)
      if (res.data.status === 'FOUND') {
        setStoredConfig(res.data.config);
      }
      setLoadingLookup(false);
      setLookup(true);
    })
    .catch((err) => {
      console.log(err)
      toast.error('Error looking up Caddy configuration')
    })
  }

  return (
    lookup === false ?
      <CaddyLookup
        site={site}
        handleChange={handleChange}
        orgId={orgId}
        platform={platform}
        loadingLookup={loadingLookup}
        findCaddyConfig={findCaddyConfig}
        setLookup={setLookup}
      /> :
      <CaddyBuilder storedConfig={storedConfig} site={site} />
  )

}

export default Caddy;
