import classNames from "classnames";

const TableHeading = ({ name, width, align }) => {

  return (
    <th
      scope="col"
      className={classNames(
        'px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider',
        {
          [width]: width !== undefined,
          'text-center': align === 'center',
          'text-right': align === 'right'
        }
      )}
    >
      {name}
    </th>
  )

}

export default TableHeading;