import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import buildPagination from '../../utils/buildPagination';
import classNames from 'classnames';

const SitePagination = ({ page, perPage, totalSites, goToPage }) => {

  let start = ((page - 1) * perPage) + 1;
  let end = start + perPage - 1;
  if (end > totalSites) end = totalSites;

  let totalPages = Math.ceil(totalSites / perPage);

  const paginationArray = buildPagination({
    page: page,
    totalPages: totalPages
  })

  return (
    <div className="py-3 flex items-center justify-between">
      <div className="flex-1 flex justify-between sm:hidden">
        {
          page > 1 &&
            <button
              onClick={() => goToPage(page - 1)}
              className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Previous
            </button>
        }
        {
          page < totalPages &&
            <button
              onClick={() => goToPage(page + 1)}
              className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
            >
              Next
            </button>
        }
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            {
              totalSites > 0 ?
                <>
                Showing <span className="font-medium">{start}</span> to <span className="font-medium">{end}</span> of{' '}
                <span className="font-medium">{totalSites}</span> sites
                </> :
                <>No results found</>
            }
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">

            {
              page > 1 &&
                <button
                  onClick={() => goToPage(page - 1)}
                  className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
            }

            {
              totalPages > 1 &&
                paginationArray.map((pg, idx) => (
                  pg != '...' ?
                    <button
                      key={pg}
                      onClick={() => goToPage(pg)}
                      className={classNames(
                        'relative inline-flex items-center px-4 py-2 border text-sm font-medium',
                        {
                          'z-10 bg-indigo-50 border-indigo-500 text-indigo-600': pg == page,
                          'bg-white border-gray-300 text-gray-500 hover:bg-gray-50': pg != page
                        }
                      )}
                    >
                      {pg}
                    </button> :
                  <span
                    key={`empty-${idx}`}
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"
                  >
                    ...
                  </span>
                ))
            }

            {
              page < totalPages &&
              <button
                onClick={() => goToPage(page + 1)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
              </button>

            }

          </nav>
        </div>
      </div>
    </div>
  )
}

export default SitePagination;