import React, { useContext, useEffect, useState } from 'react';
import SidebarPage from '../SidebarPage';
import LaunchSteps from '../launch/LaunchSteps';
import TemplateChoice from '../launch/TemplateChoice';
import FuneralHomeDetails from '../launch/FuneralHomeDetails';
import LaunchConfirmation from '../launch/LaunchConfirmation';
import LaunchLoader from '../ui/modal/dialogs/LaunchLoader';
// import axios from 'axios';
import axiosConfig from '../../axiosConfig';
import './Launch.css';
import logActivity from '../../utils/logActivity';
import { nanoid } from 'nanoid';
import { ModalContext } from '../../hooks/ModalContext';
import formatTemplateName from '../../utils/formatTemplateName';
import { useHistory } from 'react-router';


const defaultSteps = [
  { id: 'template', name: 'Template', complete: false },
  // { id: 'pages', name: 'Site pages', complete: false },
  { id: 'details', name: 'Details', complete: false },
  { id: 'confirmation', name: 'Confirmation', complete: false },
]

const LaunchPage = () => {

  let [ currentStep, setCurrentStep ] = useState('template');
  let [ template, setTemplate ] = useState(null);
  let [ fhDetails, setFhDetails ] = useState(null);
  let [ launchId, setLaunchId ] = useState(null);
  let [ allSteps, setAllSteps ] = useState([...defaultSteps]);
  // let [ launchStatusMessage, setLaunchStatusMessage ] = useState('Getting started...');

  let { handleModal } = useContext(ModalContext);
  let history = useHistory();

  const startOver = () => {
    setCurrentStep('template');
    setTemplate(null);
    setFhDetails(null);
    setLaunchId(nanoid());
    setAllSteps([...defaultSteps]);
  }

  const goToStep = (step) => {
    setCurrentStep(step);
  }

  const templateSelected = (template) => {
    console.log(`selected template: ${template.template_id}`);
    // this.setState({
    //   template: template
    // })

    let currentSteps = [...allSteps];
    let newSteps = currentSteps.map(step => {
      let { complete, ...rest } = step;
      return step.id !== 'template' ? step : {complete: true, ...rest};
    })

    setTemplate(template);
    setAllSteps(newSteps);

    // goToStep('pages');
    goToStep('details');
  }

  const saveDetails = (details) => {
    console.log(details);

    let currentSteps = [...allSteps];
    let newSteps = currentSteps.map(step => {
      let { complete, ...rest } = step;
      return step.id !== 'details' ? step : {complete: true, ...rest};
    })

    setFhDetails({...details});
    setAllSteps(newSteps);

    console.log('saved details!');
    goToStep('confirmation');
  }

  const launchSite = () => {
    console.log('launching :rocket:')
    console.log('launchId', launchId);

    handleModal(<LaunchLoader
      launchId={launchId}
    />);
    
    // setTimeout(() => {
    //   console.log('close it');
    //   handleModal(false);
    // }, 1500);

    // let the modal open...
    setTimeout(() => {
      axiosConfig.post('/api/sites/create', {
        launchId: launchId,
        templateId: template.template_id,
        ...fhDetails
      })
      .then((res) => {
  
        console.log(res);
        handleModal();
  
        logActivity({
          message: 'Site created',
          logType: 'CREATE',
          fhId: fhDetails.fhId
        });
  
        history.push(`/sites/id/${fhDetails.fhId}?created=1`);
        
        // window.location = `/sites/${fhDetails.fhId}?created=1`;
        
        // axios.get(`https://www.fhsitebuilder.com/duda/sso?site_name=${res.data.site_name}`)
        // .then((res) => {
        //   console.log(res);
        //   window.location = res.data.url;
        // })
  
      })
      .catch((err) => {
        console.log(err);
      })  
    }, 500);

  }

  useEffect(() => {

    let li = nanoid();
    setLaunchId(li);

  }, []);

  return (
    <SidebarPage fullWidth={true} docTitle='Create Site | Tukios Website Admin'>

      <LaunchSteps
        allSteps={allSteps.filter(step => step.hidden !== true)}
        currentStep={currentStep}
        goToStep={goToStep}
        templateName={template ? formatTemplateName(template.template_name) : null}
        fhName={fhDetails ? fhDetails.fhName : null} />
      
      <TemplateChoice
        currentStep={currentStep}
        setTemplate={templateSelected} />

      {/* <SitePages
        currentStep={currentStep}
        template={template}
      /> */}

      <FuneralHomeDetails
        currentStep={currentStep}
        saveDetails={saveDetails}
        backFn={startOver} />

      <LaunchConfirmation
        currentStep={currentStep}
        template={template}
        details={fhDetails}
        launchSite={launchSite}
        backFn={() => goToStep('details')} />

    </SidebarPage>
  )

}

export default LaunchPage;