import { AnnotationIcon, UserGroupIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import axiosConfig from '../../axiosConfig';

const AdditionalDetails = ({ currentStep, additionalInfo, setAdditionalInfo }) => {

  const inputClasses = 'shadow appearance-none border border-gray-200 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none';

  const handleChange = (e) => {
    setAdditionalInfo({
      ...additionalInfo,
      [e.target.name]: e.target.value
    })
  }
  
  let [ aes, setAes ] = useState([]);
  let [ designers, setDesigners ] = useState([]);

  useEffect(() => {
    axiosConfig.get('/api/designers')
    .then((res) => {
      setDesigners(res.data);
    })
    axiosConfig.get('/api/users')
    .then((res) => {
      setAes(res.data.filter(u => u.sub_type == 'ae'))
    })
  }, []);

  if (currentStep.id !== 'additional') return null;
  return (
    <form className="space-y-8 divide-y divide-gray-200">
      <div className="space-y-8 divide-y divide-gray-200">

      <div className='bg-white p-5 space-y-4 shadow rounded'>
        <div className='text-lg font-medium flex items-center'>
          <UserGroupIcon className='h-5 w-5 mb-0.5 mr-1 text-tukios-navy' />
          <div>Team Members</div>
        </div>

        <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

          <div className="sm:col-span-3">
            <label htmlFor="ae" className="block text-sm font-medium text-gray-700">
              Account Executive
            </label>
            <select
              name="ae"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
              value={additionalInfo.ae}
              onChange={handleChange}
            >
              {/* <option disabled value="chooseProvider">Choose one</option> */}
              <option value="" disabled>Select one</option>
              {
                aes.map(ae => (
                  <option key={ae.id} value={ae.id}>{ae.name}</option>
                ))
              }
            </select>
          </div>

          <div className="sm:col-span-3">
            <label htmlFor="designer" className="block text-sm font-medium text-gray-700">
              Designer <span className="text-gray-500 text-sm font-normal">- optional</span>
            </label>
            <select
              name="designer"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none sm:text-sm rounded shadow"
              value={additionalInfo.designer}
              onChange={handleChange}
            >
              <option value="" disabled>Select one</option>
              {
                designers.map(designer => (
                  <option key={designer.user_id} value={designer.user_id}>{designer.name}</option>
                ))
              }
            </select>
          </div>


        </div>


      </div>

        <div className='bg-white p-5 space-y-4 shadow rounded'>
          <div className='text-lg font-medium flex items-center'>
            <AnnotationIcon className='h-5 w-5 mb-0.5 mr-1 text-tukios-navy' />
            <div>Florist Info <span className="text-gray-500 text-sm font-normal">- Optional</span></div>
          </div>

          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

            <div className="sm:col-span-6">
              {/* <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label> */}
              <div>
                <textarea
                  name="floral"
                  type="text"
                  placeholder="Tukios Florist Shop - Ogden, UT"
                  onChange={handleChange}
                  value={additionalInfo.floral}
                  className={inputClasses}
                ></textarea>
              </div>
            </div>

          </div>
        </div>

        <div className='bg-white p-5 space-y-4 shadow rounded'>
          <div className='text-lg font-medium flex items-center'>
            <AnnotationIcon className='h-5 w-5 mb-0.5 mr-1 text-tukios-navy' />
            <div>Additional Notes <span className="text-gray-500 text-sm font-normal">- Optional</span></div>
          </div>

          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

            <div className="sm:col-span-6">
              {/* <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label> */}
              <div>
                <textarea
                  name="additional"
                  type="text"
                  placeholder="Anything else the team should know?"
                  onChange={handleChange}
                  value={additionalInfo.additional}
                  className={inputClasses}
                ></textarea>
              </div>
            </div>

          </div>
        </div>

      </div>
    </form>
  )
}

export default AdditionalDetails;