import axiosConfig from '../axiosConfig';
import { userId } from './users';

const logActivity = ({ message, fhId, logType, mute=false }) => {

  axiosConfig.post('/api/log', {
    message: message,
    fhid: fhId,
    userId: userId(),
    logType: logType ?? 'OTHER',
    mute: mute
  })

}

export default logActivity;