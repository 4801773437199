import ContentShimmer from 'react-content-shimmer'

const LineShimmer = () => {
  return (
    <ContentShimmer
      rounded='7px'
      style={{
        width: '80%',
        height: '16px'
      }}
    />
  )
}

const DetailCard = ({ site, siteLoaded }) => {
  return (
    <dl className="divide-y divide-gray-200">
      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Firm Name</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">{ !siteLoaded ? <LineShimmer /> : site.name ? site.name : '-' }</span>
          <span className="ml-4 flex-shrink-0">
            <button
              type="button"
              className="bg-white rounded-md font-medium text-tukios-dark hover:text-tukios focus:outline-none"
            >
              Update
            </button>
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Domain</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">{ !siteLoaded ? <LineShimmer /> : site.apex ? site.apex : '-' }</span>
          <span className="ml-4 flex-shrink-0">
            <button
              type="button"
              className="bg-white rounded-md font-medium text-tukios-dark hover:text-tukios focus:outline-none"
            >
              Update
            </button>
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Email address</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">{ !siteLoaded ? <LineShimmer /> : site.email ? site.email : '-' }</span>
          <span className="ml-4 flex-shrink-0">
            <button
              type="button"
              className="bg-white rounded-md font-medium text-tukios-dark hover:text-tukios focus:outline-none"
            >
              Update
            </button>
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Phone number</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">{ !siteLoaded ? <LineShimmer /> : site.phone ? site.phone : '-' }</span>
          <span className="ml-4 flex-shrink-0">
            <button
              type="button"
              className="bg-white rounded-md font-medium text-tukios-dark hover:text-tukios focus:outline-none"
            >
              Update
            </button>
          </span>
        </dd>
      </div>
      <div className="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt className="text-sm font-medium text-gray-500">Initial location</dt>
        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span className="flex-grow">

            {
              !siteLoaded ? <LineShimmer /> :
                site.address ?
                <>{ site.address }<br/>{ site.city } { site.state } { site.zip }</> : '-'
            }

          </span>
          <span className="ml-4 flex-shrink-0">
            <button
              type="button"
              className="bg-white rounded-md font-medium text-tukios-dark hover:text-tukios focus:outline-none"
            >
              Update
            </button>
          </span>
        </dd>
      </div>
    </dl>

    // <div className="bg-white shadow overflow-hidden sm:rounded-lg">
    //   {/* <div className="px-4 py-5 sm:px-6">
    //     <h3 className="text-lg leading-6 font-medium text-gray-900">Site Details</h3>
    //   </div> */}
    //   <div className="px-4 py-5 sm:px-6">
    //     <dl className="grid grid-cols-2 gap-x-4 gap-y-8 ">
    //       {/* <div className="">
    //         <dt className="text-sm font-medium text-gray-500">Name</dt>
    //         <dd className="mt-1 text-sm text-gray-900">
    //           {
    //             site.name ? site.name : <LineShimmer />
    //           }
    //         </dd>
    //       </div> */}
    //       <div className="sm:col-span-1">
    //         <dt className="text-sm font-medium text-gray-500">Domain</dt>
    //         <dd className="mt-1 text-sm text-gray-900">
    //           {
    //             site.apex ? site.apex : <LineShimmer />
    //           }
    //         </dd>
    //       </div>
    //       <div className="sm:col-span-1">
    //         <dt className="text-sm font-medium text-gray-500">Email address</dt>
    //         <dd className="mt-1 text-sm text-gray-900">
    //           {
    //             site.email ? site.email : <LineShimmer />
    //           }
    //         </dd>
    //       </div>
    //       <div className="sm:col-span-1">
    //         <dt className="text-sm font-medium text-gray-500">Phone number</dt>
    //         <dd className="mt-1 text-sm text-gray-900">
    //           {
    //             site.phone ? site.phone : <LineShimmer />
    //           }
    //         </dd>
    //       </div>
    //       <div className="sm:col-span-1">
    //         <dt className="text-sm font-medium text-gray-500">Location</dt>
    //         <dd className="mt-1 text-sm text-gray-900">
    //           {
    //             site.address ? ( <>{ site.address }, { site.city } { site.state } {site.zip}</> ) : <LineShimmer />
    //           }
    //         </dd>
    //       </div>
    //     </dl>
    //   </div>
    // </div>
  )
}

export default DetailCard;
