import classNames from 'classnames';

const badgeColors = {
    'Test': 'bg-red-100 text-red-800',
    'In Design': 'bg-indigo-100 text-indigo-800',
    'Staged': 'bg-yellow-100 text-yellow-800',
    'Live': 'bg-green-100 text-green-800',
    'Rebuild': 'bg-teal-100 text-teal-800',
    'In Review': 'bg-sky-100 text-sky-800',
    'Unassigned': 'bg-gray-100 text-gray-800'
}

const StatusBadge = ({ siteStatus }) => {
    
    // console.log(siteStatus);

    const defaultClasses = ['px-3', 'inline-flex', 'leading-5', 'rounded-full', 'text-xs'];

    // let colorClasses = props.site_phase === 'Live' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800';
    // let displayStatus = props.site_phase !== 'Dev' ? props.site_phase : 'Staging';

    return (
        <span className={classNames(...defaultClasses, badgeColors[siteStatus])}>
            {siteStatus}
        </span>
    )
}

export default StatusBadge;