import axiosConfig from '../axiosConfig';
import { getUserFromDesigner, userId } from './users';

const assignDesignerToFirm = (fhid, designerId) => {
  return new Promise((resolve, reject) => {
    axiosConfig.post(`/api/sites/${fhid}/assign`, {
      designerId: designerId
    })
    .then(() => resolve())
    .catch((err) => reject(err));
  })
}

const updateTrelloDesigner = (fhid, designerId) => {
  return new Promise((resolve, reject) => {

    getUserFromDesigner(designerId)
    .then((user) => {
      axiosConfig.post(`/api/sites/${fhid}/addTrelloMember`, {
        memberId: user.trello_id
      })
      .then(() => {
        axiosConfig.post(`/api/sites/${fhid}/moveTrelloCard`, {
          targetList: 'DESIGN.ASSIGNED',
          originList: 'INTAKE.READY_FOR_DESIGNER'
        })
        .then(() => resolve())
        .catch((err) => reject(err));
      })
      .catch((err) => reject(err));  
    })
    .catch((err) => reject(err));

  })
}

const assignDesigner = (fhid, designerId) => {
  return new Promise((resolve, reject) => {

    assignDesignerToFirm(fhid, designerId)
    .then(() => {
      updateTrelloDesigner(fhid, designerId)
      .then(() => {

        // logActivity({
        //   message: 'Duda project created',
        //   logType: 'CREATE',
        //   fhId: fhid
        // });
  
        resolve(true);
      })
      .catch((err) => {
        resolve('However, unable to add designer to Trello card.')
      })
    })
    .catch((err) => reject(err));

  })
}

export default assignDesigner;