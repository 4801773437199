import { useEffect, useState, useContext } from "react";
import SidebarPage from "../SidebarPage";
import SelectTemplate from "./SelectTemplate";
import { useLocation, useHistory } from 'react-router';
import { Link } from "react-router-dom";
import { isAdmin, isDesigner } from "../../utils/users";
import axiosConfig from '../../axiosConfig';
import { toast } from "react-toastify";
import { ModalContext } from "../../hooks/ModalContext";
import LaunchLoader from "../ui/modal/dialogs/LaunchLoader";
import { nanoid } from 'nanoid';
import logActivity from "../../utils/logActivity";

// const defaultSteps = [
//   { id: 'template', name: 'Choose a Template', complete: false },
//   // { id: 'pages', name: 'Site pages', complete: false }
// ]

const CreateDudaProject = () => {

  let { handleModal } = useContext(ModalContext);

  const location = useLocation();
  let history = useHistory();

  // let [ allSteps, setAllSteps ] = useState([ ...defaultSteps ]);
  // let [ currentStep, setCurrentStep ] = useState('template');

  // let [ pages, setPages ] = useState({});

  const fhid = location.state?.fhid;

  let [ fhInfo, setFhInfo ] = useState(undefined);

  // let [ template, setTemplate ] = useState(null);

  const templateSelected = (e) => {

    console.log(e);
    let launchId = nanoid();

    handleModal(<LaunchLoader launchId={launchId} />);

    axiosConfig.post(`/api/sites/create/${fhid}`, { template: e, launchId: launchId })
    .then(() => {

      logActivity({
        message: 'Duda project created',
        logType: 'CREATE',
        fhId: fhid
      });

      handleModal(false);

      history.push(`/sites/id/${fhid}?created=1`);

    })
    .catch((err) => {
      console.log(err);
      toast.error('Error creating new project');
    })

    // let currentSteps = [...allSteps];
    // let newSteps = currentSteps.map(step => {
    //   let { complete, ...rest } = step;
    //   return step.id !== 'template' ? step : {complete: true, ...rest};
    // })

    // setTemplate(e);
    // setAllSteps(newSteps);

    // setCurrentStep('pages');

  }

  // const createProject = () => {
  //   console.log(pages);
  //   console.log(template);

  //   axiosConfig.post(`/api/sites/create/${fhid}`, { template: template })
  //   .then((res) => {
  //     console.log(res.data);
  //   })
  //   .catch((err) => {
  //     toast.error('Unable to post :(');
  //   })
  // }

  useEffect(() => {

    if (fhid) {
      axiosConfig.get(`/api/sites/${fhid}`)
      .then((res) => setFhInfo(res.data))
      .catch((err) => {
        console.log(err);
        toast.error(`Error retrieving details for firm ${fhid}`)
      })
    }

  }, []);

  if (!fhid) return (
    <SidebarPage fullWidth={true} docTitle='Create Site | Tukios Website Admin'>
      <h1 className='text-lg'>
        Duda projects can only be created via&nbsp;
        <Link
          to={{ pathname: '/sites/me' }}
          className='text-tukios-green underline'
        >
          My Sites
        </Link>.
      </h1>
    </SidebarPage>
  )

  if (!isDesigner() && (!isAdmin())) return (
    <SidebarPage fullWidth={true} docTitle='Create Site | Tukios Website Admin'>
      <h1 className='text-lg'>
        Duda projects can only be created by designers.
      </h1>
    </SidebarPage>
  )

  return (
    <SidebarPage fullWidth={true} docTitle='Create Site | Tukios Website Admin'>

      <div className="relative bg-tukios rounded-md mb-3">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="pr-16 sm:text-center sm:px-16">
            <p className="text-white">
              <span className="inline">
                Creating new Duda project for&nbsp;
                <Link
                  className='font-bold underline'
                  to={{
                    pathname: `/sites/id/${fhid}`
                  }}
                >
                  { fhInfo === undefined ? `firm ${fhid}` : fhInfo.name }
                </Link>
              </span>
            </p>
          </div>
        </div>
      </div>


      {/* <LaunchSteps
        allSteps={allSteps}
        currentStep={currentStep}
        // goToStep={goToStep}
        templateName={template ? formatTemplateName(template.template_name) : null}
        // fhName={fhDetails ? fhDetails.fhName : null}
      /> */}

      <SelectTemplate
        // currentStep='template'
        templateSelected={templateSelected}
      />

      {/* <SitePages
        currentStep={currentStep}
        template={template}
        pages={pages}
        setPages={setPages}
        createProject={createProject}
      /> */}


    </SidebarPage>
  )

}

export default CreateDudaProject;
