import { Fragment } from "react";
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import manageSite from "../../utils/manageSite";
import { useContext } from "react";
import { ModalContext } from "../../hooks/ModalContext";
import DangerDialog from "../ui/modal/dialogs/Danger";
import deleteSite from "../../utils/deleteSite";
import ThreeDotMenu from "../ui/ThreeDotMenu";
import { isAdmin, isDesigner } from "../../utils/users";
import setSiteStatus from "../../utils/setSiteStatus";
import { toast } from "react-toastify";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SiteMenu = ({ site_id, is_test, launcher_label, trello_card_id, updateSiteLabelDisplay }) => {

  let { handleModal } = useContext(ModalContext);

  const siteDeleted = () => {
    handleModal(false);
  }

  const deleteSiteConfirmation = (site_id) => {
    handleModal(<DangerDialog
      title={`Delete Site ${site_id}`}
      actionText='Delete'
      messageText='Are you sure you want to delete this site? This action cannot be undone.'
      actionFn={() => deleteSite(site_id, siteDeleted)}
    />)
  }

  const setInDesign = () => {
    updateSiteLabelDisplay(site_id, 'In Design');
    setSiteStatus(site_id, 'In Design')
    .then(() => {
      toast.success('Site sent back to designer!');
    })
    .catch((err) => {
      toast.error('Unable to send site back to designer');
    })
  }

  return (
    <ThreeDotMenu>
      <Menu.Item>
        {({ active }) => (
          <button
            onClick={() => manageSite(site_id)}
            className={classNames(
              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
              'block w-full text-left px-4 py-2 text-sm'
            )}
          >
            Site Admin
          </button>
        )}
      </Menu.Item>
      {
        trello_card_id &&
          <Menu.Item>
            {({ active }) => (
              <a
                // onClick={() => manageSite(site_id)}
                href={`https://trello.com/c/${trello_card_id}`}
                target='_blank'
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block w-full text-left px-4 py-2 text-sm'
                )}
              >
                Trello Card
              </a>
            )}
          </Menu.Item>
      }
      {
        (isAdmin() && launcher_label == 'In Review') &&
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => setInDesign()}
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block w-full text-left px-4 py-2 text-sm'
                )}
              >
                Send back to designer
              </button>
            )}
          </Menu.Item>
        }

      {
        (is_test && isAdmin()) &&
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={() => deleteSiteConfirmation(site_id)}
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block w-full text-left px-4 py-2 text-sm'
                )}
              >
                Delete test site
              </button>
            )}
          </Menu.Item>
        }
    </ThreeDotMenu>
  )
}

export default SiteMenu;