import { useState } from "react";

const useModal = () => {
  let [isOpen, setIsOpen] = useState(false);
  let [modalContent, setModalContent] = useState(null);

  let handleModal = (content = false) => {

    // setIsOpen(!isOpen);
    if (content) {
      setModalContent(content);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  return { isOpen, handleModal, modalContent };

}

export default useModal;