import { XIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import axiosConfig from '../../../axiosConfig';
import { nanoid } from 'nanoid';
import { Switch } from '@headlessui/react'
import { toast } from "react-toastify";
import { ExclamationIcon, InformationCircleIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Caddy = ({ site, storedConfig }) => {

  let [ isUpdate, setIsUpdate ] = useState(storedConfig !== undefined)

  const inputClasses = ' appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none';

  const appUrls = {
    'v1': 'manage2.tukioswebsites.com',
    'legacy': 'manage.tukioswebsites.com'
  }

  const removeRedirectUrl = (domain) => {
    let newDomains = [...caddyParams.additionalDomains.filter(d => d.id != domain.id)];
    console.log(newDomains);
    setCaddyParams({
      ...caddyParams,
      additionalDomains: [
        ...newDomains
      ]
    })
  }

  const addNewRedirectUrl = () => {
    setCaddyParams({
      ...caddyParams,
      additionalDomains: [
        ...caddyParams.additionalDomains,
        {
          id: nanoid(),
          value: ''
        }
      ]
    })
  }

  const regexPartTypes = {
    CONSTANT: 'constant',
    VARIABLE: 'variable',
    SLUG: 'slug'
  }

  const variableRegex = '(.+)';

  let [ buildingRegex, setBuildingRegex ] = useState(false);

  const partTypeClasses = {
    [regexPartTypes.CONSTANT]: 'bg-yellow-400',
    [regexPartTypes.VARIABLE]: 'bg-blue-300',
    [regexPartTypes.SLUG]: 'bg-green-300',
  }

  let [ regexArr, setRegexArr ] = useState([
    // {
    //   regex: '',
    //   value: 'obit',
    //   partType: regexPartTypes.CONSTANT
    // },
    // {
    //   regex: '',
    //   value: '12345',
    //   partType: regexPartTypes.VARIABLE
    // },
    // {
    //   regex: '',
    //   value: 'mary-skinner',
    //   partType: regexPartTypes.SLUG
    // }

  ])

  const buildCustomPathRegex = () => {
    let url;
    try {
      url = new URL(customPathUrl)
    } catch (error) {
      console.log('invalid url')
      return
    }

    console.log(url)
    console.log(url.pathname)
    let pathParts = url.pathname.split('/').filter(Boolean)
    console.log(pathParts)

    // let regex = [];
    // pathParts.map((p, pIdx) => {
    //   regex.push({
    //     pos: pIdx,
    //     value: p,
    //     partType: regexPartTypes.CONSTANT
    //   })
    // })

    let regex = pathParts.map((p, pIdx) => ({
        pos: pIdx,
        value: p,
        partType: regexPartTypes.CONSTANT
      }
    ));

    regex.sort(regexSorter)

    console.log(regex)
    setRegexArr(regex)
    setBuildingRegex(true)

  }

  const regexSorter = (a, b) => {
    return (a.pos > b.pos) ? 1 : ((b.pos > a.pos) ? -1 : 0)
  }

  let [ customPathUrl, setCustomPathUrl ] = useState('');

  let [ caddyParams, setCaddyParams ] = useState({
    orgId: site.id,
    url: `www.${site.apex}`,
    appUrl: 'manage.tukioswebsites.com',
    platform: 'legacy',
    obituarySlug: site.obituary_page,
    sitePrefix: null,
    additionalDomains: [
      {
        id: nanoid(),
        value: site.apex
      }
    ],
    redirectOptions: [
      {
        id: nanoid(),
        regex: 'obituary/(.+)$',
        match: 1,
        example: '/obituary/<slug>',
        selected: false
      },
      {
        id: nanoid(),
        regex: 'obituaries/(.+)/(.+)$',
        match: 2,
        example: '/obituaries/12345/<slug>',
        selected: false
      }
    ]
  })

  const handleChange = (e) => {
    if (e.target.name !== 'platform') {
      setCaddyParams({
        ...caddyParams,
        [e.target.name]: e.target.value
      })  
    } else {
      setCaddyParams({
        ...caddyParams,
        'platform': e.target.value,
        'appUrl': appUrls[e.target.value]
      })
    }
  }

  let [ loadingData, setLoadingData ] = useState(true);
  let [ loadingPublish, setLoadingPublish ] = useState(false);

  // const createCaddyfile = (payload) => {
  //   return new Promise((resolve, reject) => {

  //     if (storedConfig === undefined) {
  //       axiosConfig.post(`/api/caddy/publish`, payload)
  //       .then((res) => resolve(res))
  //       .catch((err) => reject(err))  
  //     } else {
  //       axiosConfig.put(`/api/caddy/publish`, payload)
  //       .then((res) => resolve(res))
  //       .catch((err) => reject(err))  
  //     }

  //   })
  // }

  const publishCaddyfile = () => {

    if (loadingPublish === true) return;
    setLoadingPublish(true);

    const payload = {
      caddyConfig: {
        organizationId: caddyParams.orgId,
        appUrl: caddyParams.appUrl,
        redirectUrls: [ ...caddyParams.additionalDomains.filter(d => Boolean(d.value)).map(d => d.value) ],
        primaryUrl: caddyParams.url,
        obituarySlug: caddyParams.obituarySlug,
        platform: caddyParams.platform,
        dudaSubdomain: caddyParams.sitePrefix,
        pathRedirects: [
          ...caddyParams.redirectOptions
            .filter(r => r.selected === true)
            .map(r => ({
              regex: r.regex,
              match: r.match
            }))
        ]  
      },
      type: isUpdate ? 'UPDATE' : 'PUBLISH'
    }
    console.log(payload)

    // const templateData = {
    //   platform: 'legacy',
    //   pathRedirects: [{ regex: 'obituary/(.+)$', match: 1 }],
    //   redirectUrls: ['crossingsfuneralcare.ca'],
    //   primaryUrl: 'www.crossingsfuneralcare.ca',
    //   obituarySlug: 'obituaries',
    //   organizationId: 662,
    //   appUrl: 'manage.tukioswebsites.com',
    //   dudaSubdomain: '662-crossings-funeral-care'
    // }
  
    axiosConfig.post(`/api/caddy/${caddyParams.platform}/${caddyParams.orgId}`, payload)
    .then((res) => {
      console.log(res.data)
      setLoadingPublish(false);
      if (res.data.status == 'FILE_CREATED') {
        toast.success('Caddyfile published!')
      } else if (res.data.status == 'FILE_EXISTS') {
        toast.warn('Caddyfile already exists')
      }
    })
    .catch((err) => {
      setLoadingPublish(false);
      toast.error('Error publishing Caddyfile')
      console.log(err)
    })
  }

  useEffect(() => {

    if (!isUpdate) {
      axiosConfig.get(`/api/sites/${site.id}/dudaPrefix`)
      .then((res) => {
        setCaddyParams({
          ...caddyParams,
          sitePrefix: res.data.prefix
        })
        setLoadingData(false)
      })
      .catch((err) => {
        setLoadingData(false)
      })  
    } else {

      const redirs = storedConfig.redirectUrls.map(r => ({
        id: nanoid(),
        value: r
      }))

      const pathRedirs = storedConfig.pathRedirects.map(r => ({
        id: nanoid(),
        regex: r.regex,
        match: r.match,
        example: '',
        selected: true
      }))

      setCaddyParams({
        orgId: storedConfig.organizationId,
        url: storedConfig.primaryUrl,
        appUrl: storedConfig.primaryUrl,
        platform: storedConfig.platform,
        obituarySlug: storedConfig.obituarySlug,
        sitePrefix: storedConfig.dudaSubdomain,
        additionalDomains: redirs,
        redirectOptions: pathRedirs    
      })
    }


  }, [])

  return (
    <>
      {
        !isUpdate ?
          <div className="mb-3 rounded-md bg-yellow-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm text-yellow-800">
                  No configuration has been saved for this firm yet, so a default configuration has been created. Publishing will create the Caddyfile and store this configuration for future use.
                </h3>
              </div>
            </div>
          </div>
          :
          <div className="mb-3 rounded-md bg-blue-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm text-blue-800">
                  A Caddyfile already exists for this firm. Updating will overwrite the existing configuration.
                </h3>
              </div>
            </div>
          </div>



      }

      <div className="flex justify-end mt-4">
        <button
          className="px-4 py-2 text-sm font-medium rounded-md text-white bg-tukios hover:bg-tukios-dark focus:outline-none"
          onClick={publishCaddyfile}
          disabled={loadingPublish === true}
        >
          {
            loadingPublish === true ? 'Loading...' : (!isUpdate ? 'Publish Caddyfile' : 'Update Caddyfile')
          }
        </button>
      </div>

      <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

        <div className="sm:col-span-1">
          <label htmlFor="orgId" className="block text-sm font-medium text-gray-700">
            Org ID
          </label>
          <div className="mt-1 flex rounded-md">
            <input
              type="text"
              name="orgId"
              onChange={handleChange}
              placeholder={site.id}
              value={caddyParams.orgId}
              className={inputClasses}
            />
          </div>
        </div>

        
        <div className="sm:col-span-5">
          <label htmlFor="url" className="block text-sm font-medium text-gray-700">
            Primary URL
          </label>
          <div className="mt-1 flex rounded-md">
            <input
              type="text"
              name="url"
              onChange={handleChange}
              placeholder="sonzinimortuary.com"
              value={caddyParams.url}
              className={inputClasses}
            />
          </div>

        </div>

        <div className="sm:col-span-2">
          <label htmlFor="obituarySlug" className="block text-sm font-medium text-gray-700">
            Obituary Slug
          </label>
          <div className="mt-1 flex rounded-md">
            <span className="inline-flex shadow-none items-center px-3 rounded-l-md border border-r-0 bg-gray-50 text-gray-500 sm:text-sm">
              /
            </span>
            <input
              type="text"
              name="obituarySlug"
              onChange={handleChange}
              placeholder="obituary"
              value={caddyParams.obituarySlug}
              className={inputClasses + ' rounded-l-none shadow-none'}
            />
          </div>

        </div>

        <div className="sm:col-span-4">
          <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
            Duda Subdomain
          </label>
          <div className="mt-1 flex rounded-md">
            <input
              name="sitePrefix"
              type="text"
              value={caddyParams.sitePrefix}
              placeholder="Loading..."
              onChange={handleChange}
              disabled={loadingData === true}
              className={inputClasses}
            />
          </div>
        </div>


        <div className="sm:col-span-2">
          <label htmlFor="platform" className="block text-sm font-medium text-gray-700">
            Obituary Platform
          </label>
          <select
            name="platform"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded"
            value={caddyParams.platform}
            onChange={handleChange}
          >
            <option value="legacy">Legacy (manage)</option>
            <option value="v1">New (manage2)</option>
          </select>
        </div>

        <div className="sm:col-span-4">
          <label htmlFor="appUrl" className="block text-sm font-medium text-gray-700">
            App URL
          </label>
          <div className="mt-1 flex rounded-md">
            <input
              type="text"
              name="appUrl"
              placeholder="manage.tukioswebsites.com"
              value={caddyParams.appUrl}
              onChange={handleChange}
              className={inputClasses}
            />
          </div>
        </div>


        <div className="sm:col-span-6">
          <p className="text-sm font-medium text-gray-700">
            Redirect URLs
            <span className="text-gray-400 font-normal">
              &nbsp;- Include all www and non-www variants, including for the primary URL
            </span>
          </p>

          <div className="space-y-4 mt-1">

            {
              caddyParams.additionalDomains.map((domain) => (
              <div key={domain.id} className="flex items-center">

                <div className="mt-1 w-full flex rounded-md ">
                  <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <input
                      type="text"
                      name="redirectUrl"
                      placeholder="www.sonzinimortuary.com"
                      className={inputClasses + ' rounded-r-none'}
                      // className="block w-full rounded-none rounded-l-md sm:text-sm border-gray-300 focus:ring-none"
                      value={domain.value}
                      onChange={(e) => {
                        let newDomains = [...caddyParams.additionalDomains];
                        setCaddyParams({
                          ...caddyParams,
                          additionalDomains: [
                            ...newDomains.filter(d => d.id !== domain.id),
                            {
                              id: domain.id,
                              value: e.target.value
                            }
                          ]
                        })
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => removeRedirectUrl(domain)}
                    className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                  >
                    <span>
                      <XIcon className="w-4 h-4" />
                    </span>
                  </button>
                </div>
{/* 
                <div className="w-full flex shadow rounded-md">
                  <input
                    type="text"
                    name="redirectUrl"
                    placeholder="sonzinifunerals.com"
                    className={inputClasses}
                    value={domain.value}
                    onChange={(e) => {
                      let newDomains = [...caddyParams.additionalDomains];
                      setCaddyParams({
                        ...caddyParams,
                        additionalDomains: [
                          ...newDomains.filter(d => d.id !== domain.id),
                          {
                            id: domain.id,
                            value: e.target.value
                          }
                        ]
                      })
                    }}
                    // onKeyPress={(e) => {
                    //   if (domainIdx === caddyParams.additionalDomains.length - 1) {
                    //     if (e.key == 'Enter') {
                    //       addNewRedirectUrl()
                    //     }
                    //   }
                    // }}
                  />
                </div>
    
                <button
                  className="bg-transparent text-red-500 ml-1"
                  onClick={() => removeRedirectUrl(domain)}
                >
                  <XIcon className="w-4 h-4" />
                </button> */}
    
              </div>
    
              ))
            }


          </div>

          <div className="mt-3 text-sm flex justify-end">
            <button
              onClick={addNewRedirectUrl}
            >
              + Add another
            </button>
          </div>
        </div>

        <div className="sm:col-span-6">
          <p className="text-sm font-medium text-gray-700">
            Obituary Path Redirects
          </p>
          
          <div className="space-y-3 mt-2">

            {
              caddyParams.redirectOptions.map(r => (
                <Switch.Group key={r.id} as="div" className="flex items-center justify-between">
                  <span className="flex-grow flex flex-col">
                    <Switch.Label as="span" className="text-sm font-mono font-medium text-gray-900" passive>
                      {r.regex}
                    </Switch.Label>
                    {
                      r.example &&
                        <Switch.Description as="span" className="text-sm text-gray-500">
                          Match paths like {r.example}
                        </Switch.Description>
                    }
                  </span>
                  <Switch
                    checked={r.selected}
                    onChange={() => {

                      let newRedirects = [ ...caddyParams.redirectOptions ];
                      let targetRedir = newRedirects.find(_r => _r.id == r.id)
                      let targetRedirIdx = newRedirects.findIndex(_r => _r.id == r.id)
                      newRedirects[targetRedirIdx] = {
                        ...targetRedir,
                        selected: !r.selected
                      }

                      setCaddyParams({
                        ...caddyParams,
                        redirectOptions: newRedirects
                      })


                      // let newCaddyParams = [ ...caddyParams ];
                      // let newRedirects = [ ...newCaddyParams.redirectOptions.filter(_r => _r.id !== r.id) ]

                      // setCaddyParams({
                      //   ...caddyParams,
                      //   redirectOptions: [
                      //     ...newRedirects,
                      //     {
                      //       regex: r.regex,
                      //       example: r.example,
                      //       selected: !r.selected
                      //     }
                      //   ]
                      // })
                    }}
                    className={classNames(
                      r.selected ? 'bg-indigo-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        r.selected ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              ))
            }


            <div className="mt-1 flex rounded-md">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <input
                  type="text"
                  className={inputClasses + ' rounded-r-none '}
                  // className="block w-full rounded-none rounded-l-md sm:text-sm border-gray-300 focus:ring-none"
                  placeholder="Enter an obituary URL to build your own"
                  value={customPathUrl}
                  onChange={(e) => setCustomPathUrl(e.target.value)}
                />
              </div>
              <button
                type="button"
                onClick={buildCustomPathRegex}
                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
              >
                <span>Build</span>
              </button>
            </div>

            { buildingRegex === true &&
              <div className="space-y-4">
                <div className="w-full flex items-center justify-center font-mono text-lg">
                  <p>
                    {
                      regexArr.map((r) => (
                        <>
                          /
                          <span
                            className={partTypeClasses[r.partType]}
                          >
                            {r.value}
                          </span>
                        </>
                      ))
                    }
                  </p>
                </div>

                <div>
                  <div className="flex flex-col w-2/3 mx-auto space-y-3">

                    {
                      regexArr.map((r) => (
                        <div className="flex items-center">
                          <div className="w-1/2 font-mono">
                            {r.value}
                          </div>
                          <div className="w-1/2">

                            <select
                              id="location"
                              name="location"
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                              defaultValue='constant'
                              onChange={(e) => {
                                let newRegexArr = [ ...regexArr ]
                                let regexIndex = newRegexArr.findIndex(_r => _r.value == r.value)
                                let newRegex = newRegexArr[regexIndex]

                                newRegexArr[regexIndex] = {
                                  ...newRegex,
                                  partType: e.target.value
                                }
                                setRegexArr(newRegexArr);

                              }}
                            >
                              <option value={regexPartTypes.CONSTANT}>Constant</option>
                              <option value={regexPartTypes.VARIABLE}>Variable</option>
                              <option value={regexPartTypes.SLUG}>Slug</option>
                            </select>

                          </div>
                        </div>
                      ))
                    }

                  </div>
                </div>
                  
                <div className="flex justify-center">
                  <button
                    className="px-4 py-2 text-sm font-medium rounded-md text-tukios bg-white border-tukios border-2 hover:bg-tukios hover:text-white focus:outline-none"
                    onClick={() => {
                      let sortedRegexArr = [ ...regexArr ]
                      sortedRegexArr.sort(regexSorter)
                      console.log(sortedRegexArr)

                      // let newRedirect = {
                      //   regex: '',
                      //   match: -1,
                      //   example: '',
                      //   selected: true
                      // }

                      // const pathMatcherArr = sortedRegexArr.map(r => {
                      //   switch(r.partType) {
                      //     case regexPartTypes.CONSTANT:
                      //       return r.value
                      //     case regexPartTypes.VARIABLE:
                      //     case regexPartTypes.SLUG:
                      //       return variableRegex
                      //     default:
                      //       return false
                      //   }
                      // })

                      let pathMatcherArr = []
                      let exampleArr = []
                      let matchIdx = -1

                      sortedRegexArr.map(r => {
                        switch(r.partType) {
                          case regexPartTypes.CONSTANT:
                            pathMatcherArr.push(r.value)
                            exampleArr.push(r.value)
                            break
                          case regexPartTypes.VARIABLE:
                            pathMatcherArr.push(variableRegex)
                            exampleArr.push('<variable>')
                            break
                          case regexPartTypes.SLUG:
                            pathMatcherArr.push(variableRegex)
                            exampleArr.push('<slug>')
                            matchIdx = r.pos + 1
                            break
                          default:
                            break
                        }
                      })

                      const pathMatchStr = pathMatcherArr.join('/') + '$'
                      console.log(pathMatchStr)

                      // {
                      //   regex: 'obituaries/(.+)/(.+)$',
                      //   match: 2,
                      //   example: '/obituary/12345/<slug>',
                      //   selected: false
                      // }

                      // let dupeRegex = caddyParams.redirectOptions.find(
                      //   _r => _r.regex == pathMatchStr && _r.match == matchIdx
                      // )

                      setCaddyParams({
                        ...caddyParams,
                        redirectOptions: [
                          ...caddyParams.redirectOptions,
                          {
                            id: nanoid(),
                            regex: pathMatchStr,
                            match: matchIdx,
                            example: exampleArr.join('/'),
                            selected: true
                          }
                        ]
                      })

                      // if (!dupeRegex) {
                      //   setCaddyParams({
                      //     ...caddyParams,
                      //     redirectOptions: [
                      //       ...caddyParams.redirectOptions,
                      //       {
                      //         regex: pathMatchStr,
                      //         match: matchIdx,
                      //         example: exampleArr.join('/'),
                      //         selected: true
                      //       }
                      //     ]
                      //   })
                      // } else {

                      //   setCaddyParams({
                      //     ...caddyParams,
                      //     redirectOptions: [
                      //       ...caddyParams.redirectOptions.filter(
                      //         _r => _r.regex !== dupeRegex.regex && _r.match !== dupeRegex.match
                      //       ),
                      //       {
                      //         ...dupeRegex,
                      //         selected: true
                      //       }
                      //     ]
                      //   })

                      // }


                      setBuildingRegex(false)
                      setRegexArr([])
                      setCustomPathUrl('')

                    }}
                  >
                    Add Regex
                  </button>
                </div>

              </div>
            }


            
          </div>

        </div>


      </div>

    </>
  )
}

export default Caddy;