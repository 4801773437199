import {
  CheckCircleIcon,
  DotsCircleHorizontalIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/solid';

const FHIDStatusBadge = ({ fhidStatus }) => {
    switch(fhidStatus) {
      case 'NOT_SET':
        return <DotsCircleHorizontalIcon className='h-4 w-4' />;
      case 'NOT_IN_USE':
        return <CheckCircleIcon className='h-4 w-4 text-green-500' />;
      case 'FH_EXISTS':
      case 'DUDA_EXISTS':
        return <ExclamationCircleIcon className='h-4 w-4 text-red-500' />;
      default:
        return <QuestionMarkCircleIcon className='h-4 w-4 text-yellow-500' />;
    }
}

export default FHIDStatusBadge;