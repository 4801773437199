import classNames from "classnames";

const Table = ({ children, bottomPad }) => {

  return (
    <div className="py-2 align-middle inline-block min-w-full">
      <div className={classNames(
        { 'pb-60': bottomPad === true },
        { 'pb-12': bottomPad !== true },
        'overflow-scroll sm:rounded-lg'
      )}>
        <table className="min-w-full divide-y divide-gray-200">
          {children}
        </table>
      </div>
    </div>
  )

}

export default Table;