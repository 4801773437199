import './App.css';
import TestPage from './components/TestPage';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CookiesProvider, useCookies } from 'react-cookie';
import Login from './components/Login';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import axiosConfig from './axiosConfig';
import MainRoutes from './components/MainRoutes';
import { ModalProvider } from './hooks/ModalContext';
import AuthRoute from './components/AuthRoute';
import NotFound from './components/pages/NotFound';

function App() {

  // if (cookies.access_token === undefined) return <Login />
  
  const [ cookies ] = useCookies();
  const isLoggedIn = cookies.access_token !== undefined;

  if (isLoggedIn === true) {
    axiosConfig.defaults.headers.common['Authorization'] = `bearer ${cookies.access_token}`;
  }

  console.log('logged in?', isLoggedIn);


  return (
    <ModalProvider>
      <CookiesProvider>
        <BrowserRouter>
          <Switch>

            <Route path='/login' component={Login} />

            {
              // isLoggedIn === true &&
              <TestPage>
                <MainRoutes />
              </TestPage>
            }

          </Switch>

          <ToastContainer
            transition={Slide}
            autoClose={3000}
            pauseOnFocusLoss={false}
          />
          {/* <ModalProvider>
            <TestComp />
          </ModalProvider> */}
        </BrowserRouter>
      </CookiesProvider>
    </ModalProvider>
  );
}

export default App;
