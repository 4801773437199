import ContentShimmer from 'react-content-shimmer'

const LoaderRow = (props) => {
    return (
    <tr key={props.idx} className={props.idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <ContentShimmer style={{ height: '14px', width: '50px', borderRadius: '7px' }} />
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        <ContentShimmer style={{ height: '14px', width: '90%', borderRadius: '7px' }} />
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        <ContentShimmer style={{ height: '14px', width: '90%', borderRadius: '7px' }} />
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-center">
        <ContentShimmer style={{ height: '14px', width: '80px', borderRadius: '7px', margin: '0 auto' }} />
      </td>
      {/* <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <ContentShimmer style={{ height: '22px', width: '22px', borderRadius: '100%' }} />
      </td> */}
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <div className='flex items-center justify-end'>
          <ContentShimmer style={{ height: '24px', width: '65px', borderRadius: '7px', marginRight: '7px' }} />
          <ContentShimmer style={{ height: '24px', width: '65px', borderRadius: '7px', marginRight: '7px' }} />
          <ContentShimmer style={{ height: '24px', width: '65px', borderRadius: '7px' }} />
        </div>
      </td>
    </tr>
  )
}

export default LoaderRow;
