import React from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import LaunchPage from './pages/Launch';
import SitesPage from './pages/Sites';
import DashboardPage from './pages/Dashboard';
import SitePage from './site/SitePage';
import LinkSites from './pages/LinkSites';
import IntakeForm from './pages/IntakeForm';
import NotFound from './pages/NotFound';
import MySites from './pages/MySites';
import UnassignedSites from './pages/UnassignedSites';
import { isAdmin } from '../utils/users';
import CreateDudaProject from './create_duda/CreateDudaProject';

function MainRoutes() {
  
  const { pathname } = useLocation();

  return (
    <Switch>
      
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

      {/* <AuthRoute path='/' exact component={DashboardPage} /> */}
      <Route exact path="/">
        <Redirect to="/sites" />
      </Route>

      <AuthRoute path='/sites' exact component={SitesPage} />
      <AuthRoute path='/sites/id/:id' component={SitePage} />
      <AuthRoute path='/sites/me' exact component={MySites} />
      <AuthRoute path='/sites/unassigned' exact component={UnassignedSites} />

      <AuthRoute path='/create' exact component={CreateDudaProject} />
      <AuthRoute path='/create/legacy' exact component={LaunchPage} />
      <AuthRoute path='/link' exact component={LinkSites} />

      <AuthRoute path='/intake' exact component={IntakeForm} />

      <Route component={NotFound} />

    </Switch>
  )
}

export default MainRoutes;
