import React from 'react';
import NavItems from './NavItems';
import SidebarLink from './SidebarLink';


const MobileNavigation = () => {

  const userType = localStorage.getItem('userType');

  return (
    <div className="mt-5 flex-1 h-0 overflow-y-auto">
      <nav className="px-2 space-y-1">
        {
          NavItems.filter(item => !item.exclude?.includes(userType)).map((item) => (
            <SidebarLink item={item} />
          ))
        }
      </nav>
    </div>
  )
}

export default MobileNavigation;
