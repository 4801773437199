import { useState, useEffect } from "react";
import TemplateItem from "./TemplateItem";
// import axios from 'axios';
import axiosConfig from '../../axiosConfig';
import TemplateLoader from "./TemplateLoader";
import TemplateSearch from "./TemplateSearch";

const TemplateList = ({ setTemplate }) => {

  let [ templates, setTemplates ] = useState([]);
  let [ loaded, setLoaded ] = useState(false);
  let [ searchTerm, setSearchTerm ] = useState('');

  useEffect(() => {
    axiosConfig.get('/api/templates', { withCredentials: true })
    .then((res) => {
      setTemplates(res.data);
      setLoaded(true);
    })
  }, []);

  const clearSearch = () => {
    setSearchTerm('');
  }

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  }

  return (
    <>
      <TemplateSearch
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        clearSearch={clearSearch} />
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
        {
          loaded ? (
            templates
            .filter(template => searchTerm === '' ? true : (template.template_name.toLowerCase().includes(searchTerm.toLowerCase())))
            .map((template) => (
              <TemplateItem
                template={template}
                key={template.template_id}
                setTemplate={setTemplate} />
            ))
          ) : (
            Array.from({ length: 3 }, (e, i) => (
              <TemplateLoader />
            ))
          )
        }
      </ul>
    </>
  )

}

export default TemplateList;

// export default class TemplateList extends Component {

//   constructor(props) {
//     super(props);
//     this.state = {
//       templates: [],
//       loaded: false
//     }
//   }

//   componentDidMount() {
//     axiosConfig.get('/api/templates', { withCredentials: true })
//     .then((res) => {
//       console.log(res);
//       this.setState({
//         templates: res.data,
//         loaded: true
//       })
//     })
//   }

//   // selectTemplate(templateId) {
//   //   console.log(`selected a template: ${templateId}`);
//   // }

//   render() {
//     return (
//       <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3">
//         {
//           this.state.loaded ? (
//             this.state.templates.map((template) => (
//               <TemplateItem
//                 template={template}
//                 key={template.template_id}
//                 setTemplate={this.props.setTemplate} />
//             ))
//           ) : (
//             Array.from({ length: 3 }, (e, i) => (
//               <TemplateLoader />
//             ))
//           )
//         }
//       </ul>
//     )
  
//   }


// }