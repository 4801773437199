import { Redirect, Route } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import NotFound from './pages/NotFound';

const AuthRoute = ({ component: Component, auth, ...rest }) => {

  const [ cookies ] = useCookies();
  const isLoggedIn = cookies.access_token !== undefined;

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (

          auth !== false ? (
            <Component {...props} />
          ) : <NotFound />

        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default AuthRoute
