import SidebarPage from "../SidebarPage";
import Table from "../ui/table/Table";
import TableBody from "../ui/table/TableBody";
import TableHeader from "../ui/table/TableHeader";
import TableHeading from "../ui/table/TableHeading";
import listSites from "../../utils/listSites";
import { useEffect, useState } from "react";
import LoaderRow from "../sites/LoaderRow";
import { Link } from "react-router-dom";
import StatusBadge from "../sites/StatusBadge";
import ThreeDotMenu from "../ui/ThreeDotMenu";
import { Menu } from '@headlessui/react';
import SiteActions from "../sites/SiteActions";
import StatsWrapper from '../dashboard/StatsWrapper';
import Stat from '../dashboard/Stat';
import {
  BeakerIcon,
  LightningBoltIcon,
  PaperAirplaneIcon,
  SparklesIcon
} from '@heroicons/react/solid';
import axiosConfig from '../../axiosConfig';
import { toast } from "react-toastify";
import manageSite from "../../utils/manageSite";
import setSiteStatus from "../../utils/setSiteStatus";
import logActivity from "../../utils/logActivity";
import { isDesigner, isAE, isAdmin, userId, getDesignerFromUser } from "../../utils/users";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrello } from '@fortawesome/free-brands-svg-icons'
import ReactTooltip from 'react-tooltip';
import { ExclamationCircleIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const MySites = () => {

  let [ isLoading, setIsLoading ] = useState(false);
  let [ sites, setSites ] = useState(undefined);
  let [ stats, setStats ] = useState([]);

  const updateSites = (listFilter) => {
    setIsLoading(true);

    listSites({ ...listFilter })
    .then((sitesData) => {
      setIsLoading(false);
      setSites(sitesData.data);
    })
    .catch((err) => alert(err));

  }

  const getDesignerStats = (designerId) => {
    axiosConfig.get(`/api/designers/${designerId}/stats`)
    .then((res) => {
      let newStats = [
        { id: 1, name: 'In Design', stat: res.data.design, icon: BeakerIcon },
        { id: 2, name: 'In Review', stat: res.data.review, icon: PaperAirplaneIcon },
        { id: 3, name: 'Staged', stat: res.data.staged, icon: SparklesIcon },
        { id: 4, name: 'Live', stat: res.data.live, icon: LightningBoltIcon }
      ];
      setStats(newStats);
    })
  }

  const sortSites = (a, b) => {
    if ( !a.duda_id ) return -1;
    if ( !b.duda_id ) return 1;

    if ( a.launcher_label == 'In Review' ) return 1;
    if ( b.launcher_label == 'In Review' ) return -1;

    return 0;
  }

  const submitForReview = (fhid) => {

    axiosConfig.post(`/api/sites/${fhid}/qa`)
    .then(() => setSiteStatus(fhid, 'In Review'))
    .then(() => {

      logActivity({
        message: 'Submitted for review',
        fhId: fhid,
        logType: 'DESIGNER'
      })

      let siteSubmitted = sites.find(site => site.id == fhid);
      siteSubmitted.launcher_label = 'In Review';

      let currentSites = sites.filter(site => site.id != fhid);

      setSites([
        ...currentSites,
        siteSubmitted
      ]);

      let currentStats = [ ...stats ];
      currentStats.find(stat => stat.name == 'In Review').stat++;
      currentStats.find(stat => stat.name == 'In Design').stat--;

      setStats(currentStats);

      toast.success('Site submitted for review!');
    })
    .catch((err) => {
      toast.error('Unable to submit for review');
    })
  }

  useEffect(() => {

    getDesignerFromUser()
    .then((designer) => {
      updateSites({ designer: designer.id })
      getDesignerStats(designer.id)
    })

    // if (isDesigner()) {
    //   getDesignerFromUser()
    //   .then((designerId) => {
    //     updateSites({ designer: designerId })
    //     getDesignerStats(designerId)
    //   })
    // } else if (isAE()) {
    //   updateSites({ ae: userId() })
    // }

  }, []);


  // if (!isAE() && !isDesigner()) return null;

  return (


    <SidebarPage docTitle='My Sites | Tukios Website Admin'>
      <div className='flex flex-col'>
        {/* <div>
          <h1 className='text-xl'>In Progress</h1>
        </div> */}

        <div className='mb-4'>
          <StatsWrapper>
            {
              stats.map(stat => (
                <Stat key={stat.id} stat={stat} />
              ))
            }
          </StatsWrapper>
        </div>

        <Table bottomPad={true}>
          <TableHeader>
            <TableHeading name="ID" width="w-1/6" />
            <TableHeading name="Name" width="w-1/4" />
            <TableHeading name="Status" width="w-1/6" />
            <TableHeading name="Date Created" width="w-1/8" />
            <TableHeading width="w-1/12" />
          </TableHeader>
          <TableBody>
            {
              sites !== undefined && isLoading === false ? (
                sites
                .sort(sortSites)
                .filter(site => site.launcher_label != 'Live')
                .map((site, siteIdx) => (
                  <tr
                    key={site.id}
                    className={siteIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                  >

                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center">
                      {
                        site.trello_card_id &&
                        <a className="mr-2 text-md" href={`https://trello.com/c/${site.trello_card_id}`}>
                          <FontAwesomeIcon icon={faTrello} />
                        </a>
                      }
                      {site.id}
                      {
                        (!site.duda_id && !site.urgent_build) &&
                          <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            New
                          </span>
                      }
                      {
                        (site.urgent_build == 1) &&
                          <>
                            <ExclamationCircleIcon data-tip="Urgent build requested" className='h-5 w-5 text-red-500 ml-1' />
                            <ReactTooltip />
                          </>
                      }

                    </td>


                    <td className="px-6 py-4 text-sm text-gray-900">
                      <Link to={{
                        pathname: `/sites/id/${site.id}`,
                        state: {
                          duda_id: site.duda_id,
                          name: site.name
                        }
                      }}>
                        {site.name}
                      </Link>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-left">
                      <StatusBadge
                        siteStatus={site.launcher_label} />
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {site.pretty_date_created}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium flex justify-end items-center">
                      
                      {
                        site.duda_id ?
                        <>
                          <SiteActions
                            launcher_label={site.launcher_label}
                            duda_id={site.duda_id}
                            site_id={site.id}
                            is_test={site.launcher_label == 'Test'}
                            show_three_dots={false}
                          />
                        </> : (isDesigner() || isAdmin()) ?
                        <>
                          <Link
                            to={{
                              pathname: `/create`,
                              state: {
                                fhid: site.id
                              }
                            }}
                            className="inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
                          >
                            Create Duda Project
                          </Link>

                        </> : <p>Project not yet created</p>

                      }
                      <ThreeDotMenu>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              onClick={() => manageSite(site.id)}
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'block w-full text-left px-4 py-2 text-sm'
                              )}
                            >
                              Site Admin
                            </button>
                          )}
                        </Menu.Item>
                        {
                          (site.launcher_label != 'In Review' && (isDesigner() || isAdmin()) && site.duda_id) &&
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => submitForReview(site.id)}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block w-full text-left px-4 py-2 text-sm'
                                  )}
                                >
                                  Submit for Review
                                </button>
                              )}
                            </Menu.Item>
                          }

                      </ThreeDotMenu>


                    </td>

                  
                  </tr>
                ))
              ) : (
                Array.from({ length: 3 }, (e, i) => (
                  <LoaderRow idx={i} />
                ))
              )
            }
          </TableBody>
        </Table>
      </div>
    </SidebarPage>
  )
}

export default MySites;