import SidebarPage from "../SidebarPage";
import FH from "../intake/FH";
import { useState, useContext } from "react";
import IntakeSteps from "../intake/IntakeSteps";
import IntakeNav from "../intake/IntakeNav";
import IntakeTemplate from "../intake/IntakeTemplate";
import Design from "../intake/Design";
import AdditionalDetails from "../intake/AdditionalDetails";
import axiosConfig from '../../axiosConfig';
import IntakeLoader from "../intake/IntakeLoader";
import { ModalContext } from "../../hooks/ModalContext";
import logActivity from "../../utils/logActivity";

const IntakeForm = () => {

  const intakeSteps = [
    { id: 'fh', name: 'Funeral Home', complete: false },
    { id: 'template', name: 'Template', complete: false },
    { id: 'design', name: 'Design', complete: false },
    { id: 'additional', name: 'Additional Info', complete: false },
  ]
  
  let [ allSteps, setAllSteps ] = useState(intakeSteps);
  let [ currentStep, setCurrentStep ] = useState(intakeSteps[0]);

  let { handleModal } = useContext(ModalContext);

  const setCurrentStepById = (stepId) => {
    setCurrentStep(allSteps.find(step => step.id == stepId));
  }

  let [ fh, setFh ] = useState({
    details: {
      name: '',
      provider: '',
      url: ''  
    },
    contact: {
      name: '',
      phone: '',
      email: ''  
    },
    locations: [],
    hostedObits: false,
    urgentBuild: false,
    urgentBuildDesc: ''
  })

  let [ template, setTemplate ] = useState({
    id: undefined,
    name: undefined
  })

  let [ design, setDesign ] = useState({
    notes: ''
  });

  let [ additionalInfo, setAdditionalInfo ] = useState({
    floral: '',
    additional: '',
    ae: '',
    designer: ''
  })


  const completeStep = (stepId, completeStatus=true, details=undefined) => {
    let newSteps = [ ...allSteps ];
    let updateIdx = newSteps.findIndex(step => step.id == stepId);
    let stepToUpdate = newSteps.find(step => step.id == stepId);
    stepToUpdate.complete = completeStatus;
    if ( details ) stepToUpdate.details = details;
    newSteps[updateIdx] = stepToUpdate;
    setAllSteps(newSteps);
  }

  const setStepDetails = (stepId, details) => {
    let newSteps = [ ...allSteps ];
    let updateIdx = newSteps.findIndex(step => step.id == stepId);
    let stepToUpdate = newSteps.find(step => step.id == stepId);
    stepToUpdate.details = details;
    newSteps[updateIdx] = stepToUpdate;
    setAllSteps(newSteps);
  }

  const validate = {
    fh: () => {

      let invalid = [];

      Object.keys(fh.details).forEach(property => {
        if ( fh.details[property] == '') invalid.push(property);
      });
      Object.keys(fh.contact).forEach(property => {
        if ( fh.contact[property] == '') invalid.push(`contact ${property}`);
      });
      if ( fh.locations.length == 0 ) invalid.push('locations (at least one)');
      if ( fh.urgentBuild === true && !fh.urgentBuildDesc ) invalid.push('urgent build reasoning')

      return invalid.length === 0 ? { isValid: true, detailMessage: fh.details.name } : { isValid: false, errors: invalid };

    },

    template: () => {
      return template.id !== undefined && template.name !== undefined
        ? { isValid: true, detailMessage: template.name }
        : { isValid: false, errors: ['template'] };
    },

    additional: () => {
      let invalid = [];
      console.log(additionalInfo);
      if ( additionalInfo.ae == '' ) invalid.push('Account Executive');
      return invalid.length === 0 ? { isValid: true } : { isValid: false, errors: invalid };
    }

  }

  const createSiteProcess = () => {
    handleModal(<IntakeLoader />);

    let data = {
      fh: fh,
      template: template,
      notes: {
        design: design.notes,
        floral: additionalInfo.floral,
        additional: additionalInfo.additional
      },
      team: {
        designer: additionalInfo.designer,
        ae: additionalInfo.ae
      },
      urgentBuild: fh.urgentBuild,
      urgentBuildDesc: fh.urgentBuildDesc
    }

    console.log(data);

    axiosConfig.post('/api/sites/intake', data)
    .then((res) => {

      logActivity({
        message: 'Intake form submitted',
        fhId: res.data.fhid,
        type: 'INTAKE'
      })

      handleModal(false);
      window.location.href = res.data.cardUrl;
    })
    .catch((err) => {
      console.log(err);
    })
  }

  const submitForm = () => {

    if (validate[currentStep.id]) {

      let validation = validate[currentStep.id]();
      if (validation.isValid === true) {
        createSiteProcess();
      } else {
        let errorMessage = `Please fill out these fields: ${validation.errors.join(', ')}`;
        alert(errorMessage);  
      }

    } else {
      createSiteProcess();
    }

  }

  return (
    <SidebarPage docTitle='Create Site | Tukios Website Admin'>
        
        <IntakeSteps
          allSteps={allSteps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
        
        <FH
          currentStep={currentStep}
          fh={fh}
          setFh={setFh}
          completeStep={completeStep}
        />
        
        <IntakeTemplate
          currentStep={currentStep}
          setCurrentStepById={setCurrentStepById}
          design={design}
          setTemplate={setTemplate}
          completeStep={completeStep}
        />

        <Design
          design={design}
          setDesign={setDesign}
          currentStep={currentStep}
        />

        <AdditionalDetails
          additionalInfo={additionalInfo}
          setAdditionalInfo={setAdditionalInfo}
          currentStep={currentStep}
        />

        <IntakeNav
          allSteps={allSteps}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          validate={validate}
          completeStep={completeStep}
          submitForm={submitForm}
        />

    </SidebarPage>
  )

}

export default IntakeForm;