import React, { useState, useEffect } from 'react';
import { MenuAlt2Icon } from '@heroicons/react/outline'
import UserDropdown from './UserDropdown';
import DesktopSidebar from './DesktopSidebar';
import MobileSidebar from './MobileSidebar';
// import listSites from "../utils/listSites";

const AppWrapper = ({ children }) => {

  let [ sidebarOpen, setSidebarOpen ] = useState(false);

  // let [ sidebarData, setSidebarData ] = useState({});

  const userName = localStorage.getItem('userName');

  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     sidebarOpen: false,
  //     userName: ''
  //   }
  // }

  // const updateSidebarData = (key, val) => {
  //   if (!(key in sidebarData)) return;
    
  //   let sd = { ...sidebarData };
  //   sd[key] = val;

  //   setSidebarData(sd);
  // }

  // useEffect(() => {

  //   console.log('rendering testpage');

  //   // listSites({ designer: 'none' })
  //   // .then((sitesData) => {
  //   //   setSidebarData({ ...sidebarData, '/sites/unassigned': sitesData.totalSites })
  //   // })

  // }, [])

  // componentDidMount() {
  //   this.setState({ userName: localStorage.getItem('userName') })
  // }

  const toggleSidebar = (open) => {
    setSidebarOpen(open);
  }

  return (
    <React.Fragment>
      <div className="h-screen flex overflow-hidden bg-gray-100">
      
        <MobileSidebar
          toggleSidebar={toggleSidebar}
          sidebarOpen={sidebarOpen}
          // sidebarData={sidebarData}
        />

        <DesktopSidebar
          // sidebarData={sidebarData}
        />

        <div className="flex flex-col w-0 flex-1 overflow-hidden">
          <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => toggleSidebar(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-end">

              <div className="ml-4 flex items-center md:ml-6">

                <span>{userName}</span>
                <UserDropdown />

              </div>

            </div>

          </div>

          {children}

        </div>
      </div>
    </React.Fragment>
  )
}

export default AppWrapper;
