import { ClipboardCopyIcon } from '@heroicons/react/solid';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import buildPreviewLink from '../../utils/buildPreviewLink';
import openSiteEditor from '../../utils/openSiteEditor';
import SiteMenu from './SiteMenu';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTrello } from '@fortawesome/free-brands-svg-icons'
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'

const SiteActions = ({ launcher_label, duda_id, site_id, is_test, show_three_dots, trello_card_id, updateSiteLabelDisplay }) => {

  return (
    <React.Fragment>

      {
        duda_id ? 
        <>
          <button
            type="button"
            onClick={() => openSiteEditor(duda_id, site_id)}
            className="inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
          >
            Editor
          </button>

          {
            launcher_label != 'Live' ? 
              <a
                href={buildPreviewLink(duda_id)}
                className="ml-1 inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
              >
                Preview
              </a>
              :
              <a
                href="#"
                className="ml-1 inline-flex items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
              >
                Website
              </a>

          }
        </> :
        <p>Project not yet created</p>
      }

      {/* <span className="relative z-0 inline-flex items-stretch ml-1">
        <a
          href={buildPreviewLink(duda_id)}
          target="_blank"
          rel="noreferrer"
          className="relative inline-flex flex-grow items-center justify-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded-l text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
        >
          Preview
        </a>
        <CopyToClipboard
          text={buildPreviewLink(duda_id)}
          onCopy={() => toast.success('Preview link copied to clipboard!')}
        >
          <button
            className="-ml-px relative inline-flex items-center px-3 py-1 border border-gray-300 shadow-sm text-sm font-medium rounded-r text-gray-700 bg-white hover:bg-gray-50 focus:outline-none"
          >
            <ClipboardCopyIcon className="-mx-1 h-4 w-4" />
          </button>
        </CopyToClipboard>
      </span> */}

      {
        show_three_dots !== false &&
          <SiteMenu
            site_id={site_id}
            is_test={is_test}
            launcher_label={launcher_label}
            updateSiteLabelDisplay={updateSiteLabelDisplay}
            trello_card_id={trello_card_id}
          />
      }

    </React.Fragment>
  )
}

export default SiteActions;