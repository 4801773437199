import { Switch, Route } from 'react-router-dom';
import { isAdmin } from '../../utils/users';
import Actions from "./Actions";
import AdminOnlyTab from './AdminOnlyTab';
import DetailCard from "./DetailCard";
// import Hosting from './Hosting';
import Caddy from './hosting/Caddy';
import DNS from './hosting/DNS';

const TabRouter = ({ site, path, siteLoaded }) => {
  // const { path } = props.match;
  // const { path } = '';
  
  console.log(path);
  // console.log(`${path}/hosting`);

  return (
    <Switch>
      
      <Route
        path={`${path}`}
        exact
        render={() => (
          <DetailCard site={site} siteLoaded={siteLoaded} />
        )}
      />

      <Route path={`${path}/actions`} component={Actions} />
      
      <Route
        path={`${path}/caddy`}
        render={() => (
          isAdmin() ?
            <Caddy site={site} />
            // <CaddyLookup site={site} />
            : <AdminOnlyTab />
        )}
      />

      <Route
        path={`${path}/dns`}
        render={() => (
          isAdmin() ?
            <DNS site={site} />
            : <AdminOnlyTab />
        )}
      />

      {/* <Route path={`${path}/actions`} component={Actions} /> */}
    </Switch>
  )
}

export default TabRouter;