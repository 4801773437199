import { createContext } from "react";
import useModal from './useModal';
import Modal from '../components/ui/modal/Modal';

let ModalContext;
let { Provider } = (ModalContext = createContext());

let ModalProvider = ({ children }) => {
  let { isOpen, handleModal, modalContent } = useModal();
  return (
    <Provider value={{ isOpen, handleModal, modalContent }}>
      <Modal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
