import SuccessWithButtons from '../ui/modal/dialogs/SuccessWithButtons';
import { ModalContext } from "../../hooks/ModalContext";
import { useContext } from 'react';

const FuneralHomeExists = ({ firm, existingFirmSubmit }) => {
  let { handleModal } = useContext(ModalContext);

  return (
    <SuccessWithButtons
      title='Funeral home found'
      messageText={`We found funeral home ${firm.name} with ID ${firm.id}`}
      posButtonText='Yes, use this funeral home'
      negButtonText='No, enter a different ID'
      actionFn={() => existingFirmSubmit(firm, () => {handleModal(false)})}
    />
  )
}

export default FuneralHomeExists;